import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  FlatList,
  Keyboard,
  TouchableOpacity,
} from "react-native";
import {
  Badge,
  useTheme,
  TextInput,
  Button,
  Dialog,
  Portal,
} from "react-native-paper";
import { Masks, useMaskedInputProps } from "react-native-mask-input";

import MainLayout from "../../layouts/MainLayout";
import GlobalStyles from "../../../styles";
import useForm from "../../hooks/useForm";
import { useDialogConfirm } from "../../hooks/useDialogConfirm";
import api from "../../services/api";
import {
  getCurrentDate,
  handleHttpError,
  serializeQueryString,
} from "../../utils";
import ItemEventSelectable from "../../components/ItemEventSelectable";
import ItemEventAdded from "../../components/ItemEventAdded";
import SentryHandler from "../../utils/sentry_handler";

const CURRENT_DATE = getCurrentDate();

const QUERY_STRING = {
  start: CURRENT_DATE,
  status: "Active",
  order_by: "date_desc",
  paginate: false,
};

export default function EditRegisterUser({ route, navigation }) {
  const { colors, roundness } = useTheme();
  const { user: userParams, onRefresh } = route.params;

  const dialogConfirm = useDialogConfirm();

  const name = useForm(["required"]);
  const company = useForm(["required"]);
  const cpf = useForm(["required", "cpf"]);
  const phone = useForm(["required", "phone"]);

  const [searchValue, setSearchValue] = useState("");
  const [user, setUser] = useState(userParams);
  const [eventsSelected, setEventsSelected] = useState([]);
  const [eventsAdded, setEventsAdded] = useState([]);
  const [currentIndexStep, setCurrentIndexStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [currentCodeDelete, setCurrentCodeDelete] = useState(null);
  const [runRequest, setRunRequest] = useState(false);
  const [events, setEvents] = useState([]);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isDeletingEventAdded, setIsDeletingEventAdded] = useState(false);
  const firstRender = useRef(true);
  const [steps, setSteps] = useState([
    {
      title: "Inserir dados",
      active: true,
    },
    {
      title: "Eventos adicionados",
      active: false,
    },
    {
      title: "Adicionar eventos",
      active: false,
    },
  ]);

  const maskCpf = useMaskedInputProps({
    value: cpf.value,
    onChangeText: cpf.onChange,
    mask: Masks.BRL_CPF,
  });

  const maskPhone = useMaskedInputProps({
    value: phone.value,
    onChangeText: phone.onChange,
    mask: Masks.BRL_PHONE,
  });

  useEffect(() => {
    setUserParams();
  }, []);

  useEffect(() => {
    setHeader();
  }, [currentIndexStep]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    fetchEvents();
  }, [runRequest]);

  const setHeader = () => {
    navigation.setOptions({
      title: user.nome,
      headerLeft: (props) => {
        return (
          <Button
            icon="chevron-left"
            color="#fff"
            labelStyle={{ fontSize: 40 }}
            onPress={() => {
              if (currentIndexStep === 0) {
                navigation.navigate("UsersPage");
              } else {
                setCurrentIndexStep(0);
              }
            }}
          />
        );
      },
    });
  };

  const setUserParams = () => {
    name.setValue(user.nome);
    phone.setValue(user.telefone);
    cpf.setValue(user.cpf);
    company.setValue(user.empresa);
    fetchEventsSelecteds();
  };

  const fetchEventsSelecteds = async () => {
    try {
      if (!user.eventos.length) return;

      const queryObj = {
        event_codes: user.eventos.map(({ codigo_evento }) => codigo_evento),
        paginate: false,
      };

      const queryString = serializeQueryString(queryObj);
      const { data } = await api.get(`/events${queryString}`);
      setEventsAdded(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = async () => {
    if (currentIndexStep === 0) {
      if (
        cpf.isValid() &&
        name.isValid() &&
        company.isValid() &&
        phone.isValid()
      ) {
        steps[1].active = true;
        setSteps(steps);
        setCurrentIndexStep(1);
      }

      return;
    }

    if (currentIndexStep === 1) {
      steps[2].active = true;
      setSteps(steps);
      setCurrentIndexStep(2);
      return;
    }

    const payload = {
      name: name.value,
      login: cpf.value,
      cpf: cpf.value,
      phone: phone.value,
      company: company.value,
    };

    try {
      setIsSaving(true);
      await api.put(`/users/${user.id}`, payload);
      if (eventsSelected.length) {
        await api.post(`/users/${user.id}/events`, { events: eventsSelected });
      }
      navigation.navigate("UsersPage");
    } catch (error) {
      alert(handleHttpError(error));
    } finally {
      onRefresh();
      setIsSaving(false);
    }
  };

  const formStep1 = () => {
    return (
      <View>
        <TextInput
          mode="outlined"
          label="CPF"
          placeholder="Digite o CPF"
          style={{ marginTop: 10 }}
          onBlur={cpf.onBlur}
          error={cpf.error}
          {...maskCpf}
          keyboardType="numeric"
          loading={true}
        />
        {!!cpf.error && (
          <Text style={GlobalStyles.errorValidation}>{cpf.error}</Text>
        )}

        <TextInput
          mode="outlined"
          label="Nome"
          placeholder="Digite o nome"
          value={name.value}
          error={name.error}
          onChangeText={name.onChange}
          onBlur={name.onBlur}
        />
        {!!name.error && (
          <Text style={GlobalStyles.errorValidation}>{name.error}</Text>
        )}

        <TextInput
          mode="outlined"
          label="Empresa"
          placeholder="Digite o nome"
          value={company.value}
          error={company.error}
          onChangeText={company.onChange}
          onBlur={company.onBlur}
        />
        {!!company.error && (
          <Text style={GlobalStyles.errorValidation}>{company.error}</Text>
        )}
        <TextInput
          mode="outlined"
          label="Telefone"
          placeholder="Digite o telefone"
          onBlur={phone.onBlur}
          error={phone.error}
          {...maskPhone}
          keyboardType="numeric"
        />
        {!!phone.error && (
          <Text style={GlobalStyles.errorValidation}>{phone.error}</Text>
        )}
      </View>
    );
  };

  const eventsAddedStep = () => {
    return (
      <View style={{ height: "78%" }}>
        {isDeletingEventAdded && (
          <Text style={{ marginVertical: 20 }}>Excluindo evento....</Text>
        )}
        <FlatList
          data={eventsAdded}
          renderItem={({ item }) => (
            <ItemEventAdded
              item={item}
              onPress={() => {
                onPressDelete(item.code);
              }}
            />
          )}
          keyExtractor={(item, index) => `${item.id}-${index}-added`}
          onEndReachedThreshold={0.1}
        />
      </View>
    );
  };

  const formStep2 = () => {
    return (
      <View style={{ height: "78%" }}>
        <TextInput
          placeholder="Buscar por nome ou código..."
          mode="flat"
          underlineColor="transparent"
          dense={true}
          style={GlobalStyles.textField}
          value={searchValue}
          onChangeText={(value) => setSearchValue(value)}
          right={getIconRight()}
          onSubmitEditing={() => {
            setClearSearch(true);
            setRunRequest(!runRequest);
            setEvents([]);
          }}
        />
        {/* <View
          style={{ height: 10, marginVertical: 20, }}
        /> */}

        {isLoadingEvents && (
          <View style={{ height: 40, marginVertical: 40 }}>
            <ActivityIndicator color={colors.primary} size="large" />
          </View>
        )}

        {!isLoadingEvents && (
          <FlatList
            data={events}
            renderItem={({ item }) => (
              <ItemEventSelectable
                item={item}
                onPress={(eventItem) => {
                  if (eventItem.checked) {
                    const [dateFull] = eventItem.start.split(" ");
                    const date = dateFull.split("/").reverse().join("-");
                    eventsSelected.push({
                      code: item.code,
                      start: date,
                    });
                    setEventsSelected(eventsSelected);
                  } else {
                    const selecteds = eventsSelected.filter(
                      (item) => String(item.code) !== String(eventItem.code)
                    );

                    setEventsSelected(selecteds);
                  }
                }}
              />
            )}
            keyExtractor={(item, index) => `${item.id}-${index}`}
            onEndReachedThreshold={0.1}
          />
        )}
      </View>
    );
  };

  const getIconRight = () => {
    if (clearSearch) {
      return (
        <TextInput.Icon
          icon={require("../../assets/close.svg")}
          color={colors.primary}
          onPress={() => {
            setClearSearch(false);
            setSearchValue("");
            setEvents([]);
            setRunRequest(!runRequest);
            Keyboard.dismiss();
          }}
        />
      );
    }

    return (
      <TextInput.Icon
        icon={require("../../assets/search.svg")}
        onPress={() => {
          setEvents([]);
          setClearSearch(true);
          setRunRequest(!runRequest);
        }}
        color={colors.primary}
      />
    );
  };

  const buildBody = () => {
    const forms = {
      0: formStep1,
      1: eventsAddedStep,
      2: formStep2,
    };
    return forms[currentIndexStep]();
  };

  const getTitleButton = () => {
    if (isSaving) return "Salvando...";

    if (currentIndexStep === 0 || currentIndexStep === 1) return "Próximo";

    return "Finalizar";
  };

  const fetchEvents = async () => {
    try {
      setIsLoadingEvents(true);

      const queryObj = {
        ...QUERY_STRING,
        like: searchValue,
      };

      const queryString = serializeQueryString(queryObj);
      const { data } = await api.get(`/events${queryString}`);

      setEvents([
        ...events,
        ...data.map((item) => ({
          ...item,
          checked:
            user.eventos.filter((event) => event.codigo_evento === item.code)
              .length ||
            eventsSelected.filter(
              ({ code }) => String(code) === String(item.code)
            ).length,
        })),
      ]);
      setIsLoadingEvents(false);
    } catch (error) {
      new SentryHandler(error);
    } finally {
      setIsLoadingEvents(false);
    }
  };

  const handleSetActiveStep = () => {
    steps.forEach((step, idx) => {
      if (idx < currentIndexStep) {
        step.active = true;
      } else {
        step.active = false;
      }
    });

    setSteps(steps);
  };

  const onPressDelete = async (evento_code) => {
    const confirm = await dialogConfirm.show({
      title: "Confirmação",
      message: "Deseja realmente excluir esse evento?",
    });

    if (confirm === false) return;

    try {
      setIsDeletingEventAdded(true);
      await api.delete(`/users/${user.id}/events/${evento_code}`);
      user.eventos = user.eventos.filter(
        ({ codigo_evento }) => codigo_evento !== evento_code
      );
      setUser(user);

      if (!user.eventos.length) {
        setEventsAdded([]);
      } else {
        fetchEventsSelecteds();
      }
    } catch (error) {
      alert(handleHttpError(error));
    } finally {
      setIsDeletingEventAdded(false);
    }
  };

  return (
    <MainLayout>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F5F6FA",
          paddingHorizontal: 14,
          paddingVertical: 20,
          borderTopEndRadius: 10,
          borderTopStartRadius: 10,
          borderBottomEndRadius: 10,
          borderBottomStartRadius: 10,
          marginHorizontal: 8,
          marginTop: 16,
        }}
      >
        {steps.map((item, index) => {
          return (
            <TouchableOpacity
              onPress={() => {
                if (currentIndexStep >= index) {
                  setCurrentIndexStep(index);
                  handleSetActiveStep();
                }
              }}
              key={`${index}-steps`}
            >
              <View
                style={{ flexDirection: "row", marginHorizontal: 20 }}
                key={item.title}
              >
                <Badge
                  style={{
                    backgroundColor: item.active ? colors.primary : "#a8a9ac",
                    color: "#fff",
                  }}
                >
                  {index + 1}
                </Badge>
                <Text
                  style={{
                    marginLeft: 4,
                    color: item.active ? colors.primary : "#000",
                  }}
                >
                  {item.title}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </View>

      <View style={{ padding: 10 }}>
        {buildBody()}
        <Button
          mode="contained"
          disabled={isSaving}
          style={{
            marginTop: 10,
            marginBottom: 20,
            height: 45,
            justifyContent: "center",
            width: "100%",
          }}
          onPress={handleClick}
          color={colors.primary}
        >
          <Text style={{ color: colors.accent }}>{getTitleButton()}</Text>
        </Button>
      </View>
    </MainLayout>
  );
}
