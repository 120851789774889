import axios from "axios";
import Storage from "../utils/storage";

const url = process.env.REACT_APP_API_URL;
console.log(url);

const api = axios.create({
  baseURL: url,
  timeout: 2000000,
});

const getHeaders = async (config) => {
  const app_key = process.env.REACT_APP_API_KEY;
  if (!app_key) throw "API_KEY não informada";

  const header = {
    ApiKey: `Bearer ${app_key}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const token = await Storage.getToken();
  if (token) header.Authorization = `Bearer ${token}`;

  config.headers = header;

  return config;
};

api.interceptors.request.use(getHeaders);

export default api;
