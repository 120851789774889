import { createContext, useContext, useMemo, useState } from "react";
import { View, Text, Linking } from "react-native";
import { useTheme, Button, Portal, Dialog } from "react-native-paper";

const DialogConfirmContext = createContext();

let resolveCallback;

const DialogConfirmProvider = ({ children }) => {
  const { colors, roundness } = useTheme();
  const [showDialog, setShowDialog] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(true);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);

  const show = ({ title, message, showCancelButton }) => {
    setTitle(title);
    console.log(title);
    setMessage(message);
    setShowDialog(true);
    setShowCancelButton(
      showCancelButton === undefined ? true : showCancelButton
    );

    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  const hide = () => {
    setShowDialog(false);
  };

  return (
    <DialogConfirmContext.Provider
      value={{
        show,
        hide,
      }}
    >
      {children}

      <Portal>
        <Dialog
          visible={showDialog}
          onDismiss={hide}
          style={{
            width: 350,
            alignSelf: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              height: 60,
              backgroundColor: colors.warning,
              justifyContent: "center",
              alignItems: "center",
              borderTopEndRadius: roundness,
              borderTopStartRadius: roundness,
            }}
          >
            <Text
              style={{
                fontSize: 20,
                fontWeight: "bold",
                color: "#000",
              }}
            >
              {title}
            </Text>
          </View>

          <Text
            style={{
              marginVertical: 30,
              marginHorizontal: 10,
              textAlign: "center",
            }}
          >
            {message}
          </Text>
          <Dialog.Actions>
            {showCancelButton && (
              <Button
                color={colors.primary}
                onPress={() => {
                  resolveCallback(false);
                  hide();
                }}
              >
                CANCELAR
              </Button>
            )}

            <Button
              color={colors.primary}
              onPress={() => {
                resolveCallback(true);
                hide();
              }}
            >
              OK
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </DialogConfirmContext.Provider>
  );
};

const useDialogConfirm = () => {
  const context = useContext(DialogConfirmContext);

  if (!context) {
    throw new Error("useDialogConfirm não possuí context");
  }

  return context;
};

export { DialogConfirmProvider, useDialogConfirm };
