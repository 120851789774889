import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { ActivityIndicator, View, Dimensions, Platform } from "react-native";
import { useTheme } from "react-native-paper";

import Events from "./pages/Events";
import Login from "./pages/Login";
import EventDetails from "./pages/EventDetails";
import CheckinCpf from "./pages/CheckinCpf";
import CheckinParticipants from "./pages/CheckinParticipants";
import QuickSubscription from "./pages/QuickSubscribtion";
import ScannerQrCode from "./pages/ScannerQrCode";
import HistoryCheckin from "./pages/HistoryCheckin";
import FutureEvents from "./pages/FutureEvents";
import UsersPage from "./pages/Users";
import RegisterUser from "./pages/RegisterUser";
import EditRegisterUser from "./pages/EditRegisterUser";
import ModalLogout from "./components/ModalLogout";
import Storage from "./utils/storage";
import { getEnvironmentText } from "./utils";
import { useMediaQuery } from "react-responsive";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

function Routers() {
  const { colors } = useTheme();

  const [showUsersPage, setShowUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isMobileScreen = useMediaQuery({
    maxDeviceWidth: 1224,
  });

  useEffect(() => {
    fecthUserType();
  }, []);

  const fecthUserType = async () => {
    const type = await Storage.getTypeUser();
    setShowUsers(type === "interno");
    setIsLoading(false);
  };

  const headerStyle = {
    backgroundColor: colors.primary,
    borderBottomColor: colors.primary,
    shadowRadius: 0,
    shadowOffset: {
      height: 0,
    },
  };

  const environments = {
    development: "(Dev)",
    homologation: "(Hm)",
    local: "(Local)",
  };

  function Root() {
    return (
      <Drawer.Navigator
        initialRouteName="Eventos"
        screenOptions={{
          drawerStyle: {
            width:
              Platform.OS === "web"
                ? isMobileScreen
                  ? 300
                  : Dimensions.get("window").width / 4.2
                : 300,
          },
        }}
      >
        <Stack.Screen
          name="Eventos"
          component={Events}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
            title: `Check-in Sebrae Minas ${getEnvironmentText(
              process.env.REACT_APP_LOCAL
            )}`,
          }}
        />
        <Stack.Screen
          name="FutureEvents"
          component={FutureEvents}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
            title: "Acompanhamento de eventos",
          }}
        />
        {showUsersPage && (
          <Stack.Screen
            name="UsersPage"
            component={UsersPage}
            options={{
              headerTintColor: colors.accent,
              headerStyle: headerStyle,
              title: "Gestão de parceiros",
            }}
          />
        )}

        <Stack.Screen
          name="Sair"
          component={ModalLogout}
          options={{
            title: "Sair",
          }}
        />
      </Drawer.Navigator>
    );
  }

  if (isLoading) {
    return (
      <View style={{ height: 40, marginVertical: 40 }}>
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );
  }

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Login">
        <Stack.Screen
          name="Login"
          component={(props) =>
            Login({ ...props, handlerContextUser: fecthUserType })
          }
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="Root"
          component={Root}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="EventDetails"
          component={EventDetails}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
          }}
        />
        <Stack.Screen
          name="RegisterUser"
          component={RegisterUser}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
            title: "Cadastro do usuário",
          }}
        />
        <Stack.Screen
          name="EditRegisterUser"
          component={EditRegisterUser}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
          }}
        />

        <Stack.Screen
          name="CheckinCpf"
          component={CheckinCpf}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
            title: "Check-in por CPF",
          }}
        />
        <Stack.Screen
          name="ScannerQrCode"
          component={ScannerQrCode}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
            title: "Checkin por Qr-code",
          }}
        />
        <Stack.Screen
          name="CheckinParticipants"
          component={CheckinParticipants}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
            title: "Check-in por participantes",
          }}
        />
        <Stack.Screen
          name="QuickSubscription"
          component={QuickSubscription}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
            title: "Inscrição rápida",
          }}
        />
        <Stack.Screen
          name="HistoryCheckin"
          component={HistoryCheckin}
          options={{
            headerTintColor: colors.accent,
            headerStyle: headerStyle,
            title: "Histórico checkin",
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default Routers;
