import { useState, useEffect, useRef } from "react";
import {
  View,
  ActivityIndicator,
  Text,
  ScrollView,
  StyleSheet,
  Platform,
  FlatList,
} from "react-native";
import {
  useTheme,
  Snackbar,
  Button,
  Modal as ModalPaper,
  TextInput,
  Subheading,
} from "react-native-paper";
import { handleHttpError } from "../../utils";
import api from "../../services/api";
import MainLayout from "../../layouts/MainLayout";
import ItemEvent from "../../components/ItemEvent";
import {
  getCurrentDate,
  serializeQueryString,
  getNameMonth,
} from "../../utils";
import { TextInputMask } from "react-native-masked-text";
import SentryHandler from "../../utils/sentry_handler";

function FutureEvents({ route, navigation }) {
  const { colors } = useTheme();
  const QUERY_STRING = {
    limit: 10,
    start: "",
    status: "Active",
    order_by: "date_asc",
  };
  // const { event, onRefresh } = route.params;

  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState("");
  const [start, setStart] = useState("");
  const [finish, setFinish] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingMoreEvents, setLoadingMoreEvents] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [fetchingEvents, setFetchingEvents] = useState(true);
  const [runRequest, setRunRequest] = useState(false);
  const [runRequestLoadMore, setRunRequestLoadMore] = useState(false);
  const firstRender = useRef(true);
  const isAndroid = Platform.OS === "android";

  useEffect(() => {
    setTitleHeader();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [runRequest]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    fetchLoadMore();
  }, [runRequestLoadMore]);

  const setTitleHeader = () => {
    navigation.setOptions({
      title: "Acompanhamento de eventos",
    });
  };

  const getParsedDateString = (strDate) => {
    var strSplitDate = String(strDate).split("/");
    var date = strSplitDate[2] + "-" + strSplitDate[1] + "-" + strSplitDate[0];
    return date.toString();
  };

  const getLoader = () => {
    return (
      <View style={{ height: 40, marginVertical: 40 }}>
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );
  };

  const fetchLoadMore = async () => {
    const queryObj = {
      ...QUERY_STRING,
      like: search,
      page: currentPage,
    };

    if (start !== "") {
      queryObj.start = getParsedDateString(start);
    }

    if (finish !== "") {
      queryObj.finish = getParsedDateString(finish);
    }

    try {
      setLoadingMoreEvents(true);
      const queryString = serializeQueryString(queryObj);
      const { data } = await api.get(`/events${queryString}`);
      setEvents([...events, ...data.data]);
      setTotalPage(data.last_page);
    } catch (error) {
      alert(handleHttpError(error));
      new SentryHandler(error);
    } finally {
      setLoadingMoreEvents(false);
    }
  };

  const handleScrollLoadMore = () => {
    if (!loadingMoreEvents) {
      if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1);
        setRunRequestLoadMore(!runRequestLoadMore);
      }
    }
  };

  const fetchEvents = async () => {
    const queryObj = {
      ...QUERY_STRING,
      like: search,
      page: currentPage,
    };

    if (start !== "") {
      queryObj.start = getParsedDateString(start);
    }

    if (finish !== "") {
      queryObj.finish = getParsedDateString(finish);
    }

    if (firstRender.current) {
      // Primeiro loading, carregar o eventos de 7 dias atrás
      const start = getCurrentDate();
      const today = new Date();
      today.setDate(today.getDate() - 7);

      const month = today.getMonth() + 1;
      const monthText = month.toString().padStart(2, "0");
      const todayText = today.getDate().toString().padStart(2, "0");
      const finish = `${today.getFullYear()}-${monthText}-${todayText}`;

      queryObj.start = finish;
      queryObj.finish = start;
    }

    const queryString = serializeQueryString(queryObj);
    try {
      setFetchingEvents(true);

      const { data } = await api.get(`/events${queryString}`);
      setEvents(data.data);
      setTotalPage(data.last_page);
    } catch (error) {
      alert(handleHttpError(error));
      new SentryHandler(error);
    } finally {
      setFetchingEvents(false);
    }
  };

  const getLoadingMore = () => {
    return loadingMoreEvents ? getLoader() : null;
  };

  const buildBody = () => {
    if (fetchingEvents) return getLoader();

    if (!fetchingEvents && events.length === 0)
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Subheading>Desculpa, nenhum evento encontrado</Subheading>
        </View>
      );

    if (search && events.length === 0)
      return (
        <View style={GlobalStyle.containerCenter}>
          <Error error="Nenhum evento encontrado" />
        </View>
      );

    return (
      <FlatList
        data={events}
        renderItem={({ item }) => (
          <ItemEvent
            item={item}
            onPress={() => {
              navigation.navigate("HistoryCheckin", { event: item });
            }}
          />
        )}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        onEndReached={handleScrollLoadMore}
        onEndReachedThreshold={0.1}
        ListFooterComponent={getLoadingMore()}
        style={{ height: 300 }}
      />
    );
  };

  return (
    <>
      <MainLayout>
        <View style={{ paddingVertical: 20, paddingHorizontal: 16, flex: 1 }}>
          <View
            style={{ flexDirection: Platform.OS == "web" ? "row" : "column" }}
          >
            <TextInput
              label="Buscar eventos (Código / Nome)"
              style={{
                backgroundColor: "#fff",
                width: Platform.OS == "web" ? "24%" : "100%",
                marginRight: Platform.OS == "web" ? 10 : 0,
              }}
              value={search}
              onChangeText={(text) => setSearch(text)}
            />
            <TextInput
              label="Data de inicio (DD/MM/YYYY)"
              style={{
                backgroundColor: "#fff",
                width: Platform.OS == "web" ? "24%" : "100%",
                marginRight: Platform.OS == "web" ? 10 : 0,
              }}
              value={start}
              render={(props) => (
                <TextInputMask
                  {...props}
                  type={"datetime"}
                  onChangeText={(text) => setStart(text)}
                  options={{
                    format: "DD/MM/YYYY",
                  }}
                />
              )}
              right={
                <TextInput.Icon
                  icon={require("../../assets/calendar.svg")}
                  color={colors.primary}
                />
              }
            />
            <TextInput
              label="Data de inicio (DD/MM/YYYY)"
              style={{
                backgroundColor: "#fff",
                width: Platform.OS == "web" ? "24%" : "100%",
                marginRight: Platform.OS == "web" ? 10 : 0,
              }}
              value={finish}
              render={(props) => (
                <TextInputMask
                  {...props}
                  type={"datetime"}
                  onChangeText={(text) => setFinish(text)}
                  options={{
                    format: "DD/MM/YYYY",
                  }}
                />
              )}
              right={
                <TextInput.Icon
                  icon={require("../../assets/calendar.svg")}
                  color={colors.primary}
                />
              }
            />
            <Button
              mode="contained"
              style={{
                marginTop: 10,
                marginBottom: 20,
                height: 45,
                justifyContent: "center",
                width: Platform.OS == "web" ? "25%" : "100%",
                marginRight: Platform.OS == "web" ? 10 : 0,
              }}
              onPress={() => {
                setCurrentPage(1);
                setRunRequest(!runRequest);
              }}
              loading={fetchingEvents}
              disabled={fetchingEvents}
              color={colors.primary}
            >
              <Text style={{ color: "#fff" }}>
                {fetchingEvents ? "Buscando..." : "Buscar"}
              </Text>
            </Button>
          </View>
          {buildBody()}
          {/* <View style={{ flex: 1, marginTop: 10 }}>
            
          </View>
          <View style={{ flex: 1, marginTop: 10 }}>
           
          </View> */}
          {/* <View style={{ height: 50 }} /> */}
        </View>
      </MainLayout>
    </>
  );
}

const styles = StyleSheet.create({
  modalView: {
    margin: 0,
    justifyContent: "flex-end",
  },
  containerStyle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },
  content: {
    width: "100%",
    height: "50%",
    backgroundColor: "#fff",
  },
});
export default FutureEvents;
