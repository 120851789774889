import { createContext, useContext, useState } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { useTheme, Portal, Dialog, Avatar, Button } from "react-native-paper";

const DialogResultMessageContext = createContext();

const DialogResultMessageProvider = ({ children }) => {
  const { colors, roundness } = useTheme();
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState(false);
  const [title, setTitle] = useState(null);

  const show = ({ error = false, message }) => {
    setTitle(message);
    setError(error);
    setShowDialog(true);
  };

  const hide = () => {
    setShowDialog(false);
  };

  const getStyleHeaderDialog = () => {
    return {
      width: "100%",
      height: 60,
      backgroundColor: error ? colors.danger : colors.success,
      justifyContent: "center",
      alignItems: "center",
      borderTopEndRadius: roundness,
      borderTopStartRadius: roundness,
    };
  };

  return (
    <DialogResultMessageContext.Provider
      value={{
        show,
        hide,
      }}
    >
      {children}

      <Portal>
        <Dialog
          visible={showDialog}
          onDismiss={hide}
          dismissable={false}
          style={{
            width: 350,
            alignSelf: "center",
          }}
        >
          <View style={getStyleHeaderDialog()}>
            <Avatar.Icon
              icon={
                error
                  ? require("../assets/alert-circle.svg")
                  : require("../assets/check.svg")
              }
              size={30}
              color="#fff"
              style={{ backgroundColor: "none" }}
            />
          </View>
          <Text style={{ marginVertical: 10, textAlign: "center" }}>
            {title}
          </Text>
          <Dialog.Actions>
            <Button color={colors.primary} onPress={hide}>
              OK
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </DialogResultMessageContext.Provider>
  );
};

const useDialogResultMessage = () => {
  const context = useContext(DialogResultMessageContext);

  if (!context) {
    throw new Error("useDialogResultMessage não possuí context");
  }

  return context;
};

export { DialogResultMessageProvider, useDialogResultMessage };
