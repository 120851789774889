import { useState, useEffect } from "react";

import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
  ActivityIndicator,
  Platform,
} from "react-native";
import { BarCodeScanner } from "expo-barcode-scanner";
import GlobalStyles from "../../../styles";
import {
  useTheme,
  IconButton,
  Snackbar,
  Button,
  Portal,
  Modal as ModalPaper,
  TextInput,
  Title,
} from "react-native-paper";

import MainLayout from "../../layouts/MainLayout";
import useForm from "../../hooks/useForm";
import DialogAlert from "../../components/DialogAlert";
import api from "../../services/api";
import { handleHttpError } from "../../utils";
import SentryHandler from "../../utils/sentry_handler";

function ScannerQrCode({ route, navigation }) {
  const { colors } = useTheme();
  const { event, onRefresh } = route.params;

  const [scanned, setScanned] = useState(false);
  const [errorCheckin, setErrorCheckin] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isDoingCheckin, setIsDoingCheckin] = useState(false);
  const [showModalCode, setShowModalCode] = useState(false);
  const [msgErrorParticipants, setMsgErrorParticipants] = useState(null);

  const [
    visibleSnackBarErrorParticipants,
    setVisibleSnackBarErrorParticipants,
  ] = useState(false);

  const code = useForm(["required"]);

  useEffect(() => {
    requestPermission();
  }, []);

  const requestPermission = async () => {
    navigation.setOptions({
      title: event.name,
    });

    if (Platform.OS !== "web") {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      if (status !== "granted") return;
    }
  };

  const hideDialog = () => setShowDialog(false);

  const sendCheckin = async (data) => {
    const payload = { code: data };
    try {
      setShowModalCode(false);
      setIsDoingCheckin(true);
      await api.post(`/checkin`, payload);
      setErrorCheckin(null);
      setShowDialog(true);
      onRefresh();
    } catch (error) {
      setErrorCheckin(handleHttpError(error));
      setShowDialog(true);
      new SentryHandler(error);
    } finally {
      setIsDoingCheckin(false);
      setScanned(false);
      code.setValue("");
    }
  };

  const isWeb = () => Platform.OS === "web";

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    if (data) sendCheckin(data);
  };

  const onClickCheckin = () => {
    if (code.isValid()) sendCheckin(code.value);
  };

  const buildBody = () => {
    if (isDoingCheckin) {
      return (
        <View style={{ height: 40, marginVertical: 40 }}>
          <ActivityIndicator color={colors.primary} size="large" />
        </View>
      );
    }
    return isWeb() ? (
      <View style={{ backgroundColor: "#fff", padding: 20 }}>
        <Title>Informar código</Title>
        <TextInput
          mode="outlined"
          label="Código"
          placeholder="Digite o código"
          error={code.error}
          value={code.value}
          onChangeText={(v) => code.setValue(v)}
          onBlur={() => code.onBlur()}
        />

        {!!code.error && (
          <Text style={GlobalStyles.errorValidation}>{code.error}</Text>
        )}

        <Button onPress={onClickCheckin} style={{ marginTop: 20 }}>
          checkin
        </Button>
      </View>
    ) : (
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
    );
  };

  return (
    <MainLayout>
      <View style={{ alignItems: "center", marginTop: 20 }}>
        <Text
          style={{ fontWeight: "bold", color: colors.primary, fontSize: 20 }}
        >
          Escaner QR Code
        </Text>
      </View>
      <View style={{ flex: 1, marginVertical: 100 }}>{buildBody()}</View>

      <DialogAlert
        show={showDialog}
        hide={hideDialog}
        error={errorCheckin !== null}
        errorMessage={errorCheckin}
        successMessage="Checkin realizado com sucesso!"
      />

      <Snackbar
        visible={visibleSnackBarErrorParticipants}
        duration={3000}
        onDismiss={() => setVisibleSnackBarErrorParticipants(false)}
      >
        {msgErrorParticipants}
      </Snackbar>
    </MainLayout>
  );
}

export default ScannerQrCode;
