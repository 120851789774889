import { View, StyleSheet, Text } from "react-native";
import { Badge, useTheme, Avatar } from "react-native-paper";

function ItemStatistics({ icon, text, number = 0 }) {
  const { colors } = useTheme();
  return (
    <View style={styles.wrapper}>
      <Avatar.Icon
        icon={icon}
        color={colors.primary}
        size={30}
        style={{
          backgroundColor: colors.accent,
        }}
      />
      <Text>{text}</Text>
      <Badge
        style={{
          backgroundColor: colors.primary,
          color: colors.accent,
          alignSelf: "center",
          marginTop: 6,
        }}
        size={20}
      >
        {number}
      </Badge>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    margin: 0,
    alignItems: "center",
    marginBottom: 10,
    marginTop: 4,
  },
});

export default ItemStatistics;
