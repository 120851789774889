import { TextInput } from "react-native-paper";
import { StyleSheet, Text } from "react-native";
import { useState } from "react";
import GlobalStyles from "../../styles";

function TextFieldPassword({ label, value, setValue, error = null, ...props }) {
  const [showText, setShowText] = useState(false);

  return (
    <>
      <TextInput
        value={value}
        mode="outlined"
        secureTextEntry={!showText}
        style={{ marginBottom: 4 }}
        underlineColor="transparent"
        onChangeText={(text) => setValue(text)}
        right={
          <TextInput.Icon
            icon={!showText ? "eye-off" : "eye"}
            onPress={() => {
              setShowText(!showText);
            }}
          />
        }
        {...props}
      />
      {error && <Text style={styles.err}>{error}</Text>}
    </>
  );
}

const styles = StyleSheet.create({
  err: {
    color: "#DB324D",
    fontWeight: "bold",
    marginBottom: 2,
    fontSize: 12,
  },
});

export default TextFieldPassword;
