import { useState, useEffect } from "react";
import { View, ActivityIndicator, ScrollView, StyleSheet } from "react-native";
import { useTheme, Snackbar, Modal as ModalPaper } from "react-native-paper";

import { handleHttpError } from "../../utils";
import SentryHandler from "../../utils/sentry_handler";
import api from "../../services/api";
import ItemStatistics from "../../components/ItemStatistics";
import CardOptions from "../../components/CardOptions";
import MainLayout from "../../layouts/MainLayout";

function EventDetails({ route, navigation }) {
  const { colors } = useTheme();
  const { event, onRefresh } = route.params;

  const [enrollds, setEnrollds] = useState(null);
  const [loadingEnrollds, setLoadingEnrollds] = useState(true);
  const [showStatistics, setShowStatistics] = useState(true);

  const isEventFree = () => event.price === 0;

  useEffect(() => {
    fetchEnrollds();
    setTitleHeader();
    const willFocusSubscription = navigation.addListener("focus", () => {
      fetchEnrollds();
    });

    return willFocusSubscription;
  }, []);

  const setTitleHeader = () => {
    navigation.setOptions({
      title: event.name,
    });
  };

  const getLoader = () => {
    return (
      <View style={{ height: 40, marginVertical: 40 }}>
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );
  };

  const fetchEnrollds = async () => {
    try {
      setLoadingEnrollds(true);
      const { data } = await api.get(`/events/${event.id}/enrolleds`);
      setEnrollds(data);
    } catch (error) {
      alert(handleHttpError(error));
      new SentryHandler(error);
    } finally {
      setLoadingEnrollds(false);
    }
  };

  const buildStatistics = () => {
    if (loadingEnrollds && !enrollds) return getLoader();

    if (!showStatistics) return null;

    return (
      <View
        style={{
          width: "100%",
          justifyContent: "space-around",
          alignItems: "center",
          flexDirection: "row",
          paddingBottom: 10,
        }}
      >
        <ItemStatistics
          icon={require("../../assets/check.svg")}
          text="Realizados"
          number={enrollds.enrolled}
        />
        <ItemStatistics
          icon={require("../../assets/groupuser.svg")}
          text="Nº inscritos"
          number={enrollds.total}
        />
        <ItemStatistics
          icon={require("../../assets/chart.svg")}
          text="Restantes"
          number={enrollds.remaining}
        />
      </View>
    );
  };

  return (
    <>
      <MainLayout>
        <ScrollView style={{ paddingVertical: 20, paddingHorizontal: 16 }}>
          <CardOptions
            icon={require("../../assets/qr-code.png")}
            title="Check-in por QR Code"
            onPress={() => {
              navigation.navigate("ScannerQrCode", {
                event: event,
                onRefresh: () => fetchEnrollds(),
              });
            }}
          />
          <CardOptions
            icon={require("../../assets/person-video.png")}
            title="Check-in por CPF"
            onPress={() => {
              navigation.navigate("CheckinCpf", {
                event: event,
                onRefresh: () => fetchEnrollds(),
              });
            }}
          />
          <CardOptions
            icon={require("../../assets/person-circle.png")}
            title="Check-in por participantes"
            onPress={() => {
              navigation.navigate("CheckinParticipants", {
                event: event,
                onRefresh: () => fetchEnrollds(),
              });
            }}
          />

          {isEventFree() && (
            <CardOptions
              icon={require("../../assets/person-plus.png")}
              title="Inscrição rápida"
              mode="white"
              onPress={() =>
                navigation.navigate("QuickSubscription", {
                  event,
                  onRefresh: () => fetchEnrollds(),
                })
              }
            />
          )}

          {buildStatistics()}
          <View style={{ height: 50 }} />
        </ScrollView>
      </MainLayout>
    </>
  );
}

const styles = StyleSheet.create({
  modalView: {
    margin: 0,
    justifyContent: "flex-end",
  },
  containerStyle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-end",
  },
  content: {
    width: "100%",
    height: "50%",
    backgroundColor: "#fff",
  },
});
export default EventDetails;
