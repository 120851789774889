import { useState, useEffect } from "react";
import {
  Text,
  View,
  Platform,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import {
  useTheme,
  TextInput,
  IconButton,
  Subheading,
  Avatar,
  Button,
} from "react-native-paper";
import ItemHistory from "../../components/ItemHistory";
import { handleHttpError } from "../../utils";
import api from "../../services/api";
import MainLayout from "../../layouts/MainLayout";
import * as FileSystem from "expo-file-system";
import ExcelJS from "exceljs";
import { Buffer as NodeBuffer } from "buffer";
import * as Sharing from "expo-sharing";
import SentryHandler from "../../utils/sentry_handler";

export default function HistoryCheckin({ route, navigation }) {
  const { event } = route.params;
  const { colors, roundness } = useTheme();
  const [participants, setParticipants] = useState([]);
  const [msgErrorParticipants, setMsgErrorParticipants] = useState(null);
  const [loadingParticipants, setLoadingParticipants] = useState(true);
  const isAndroid = Platform.OS === "android";

  useEffect(() => {
    setTitleHeader();
    fetchParticipants();
  }, []);

  const setTitleHeader = () => {
    navigation.setOptions({
      title: event && event.name,
    });
  };

  const getDaysArray = (start, end) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const FormataStringData = (data) => {
    // Formato params: 13/07/2020 18:00
    const [dateFull] = data.split(" ");
    return dateFull.split("/").reverse().join("/");
  };

  const isCheckin = (participant, data) => {
    const find = participant.historico_checkin.filter((day) => {
      let [dateFull] = day.data_checkin.split(" ");
      dateFull = dateFull.split("-").join("/");
      const finalDate = new Date(dateFull);
      finalDate.setHours(0, 0, 0, 0);
      data.setHours(0, 0, 0, 0);

      return finalDate.getTime() === data.getTime();
    });

    return find.length > 0;
  };

  const generateShareableExcel = async () => {
    const now = new Date();
    const fileName = "YourFilename.xlsx";
    const fileUri = FileSystem.cacheDirectory + fileName;
    return new Promise((resolve, reject) => {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Me";
      workbook.created = now;
      workbook.modified = now;
      // Add a sheet to work on
      const worksheet = workbook.addWorksheet("Checkins", {});
      // Just some columns as used on ExcelJS Readme

      worksheet.mergeCells("A1", "J2");
      worksheet.getCell("A1").value = `${event.code} - ${event.name}`;

      const collums = [];
      collums.push({ header: "CPF", key: "CPF", width: 40 });
      collums.push({ header: "Nome", key: "Nome", width: 60 });
      collums.push({ header: "Email", key: "Email", width: 60 });
      collums.push({ header: "Telefone", key: "Telefone", width: 60 });

      const datasCheckin = getDaysArray(
        FormataStringData(event.start),
        FormataStringData(event.finish)
      );

      datasCheckin.map((dt, index) => {
        collums.push({
          header: dt,
          key: dt,
          width: 30,
        });
      });

      worksheet.getRow(4).values = collums.map(({ key }) => key);

      worksheet.columns = collums.map(({ key }) => ({
        key: key,
        width: 60,
      }));

      participants.map((participant) => {
        const objRow = {
          CPF: participant.cpf,
          Nome: participant.name,
          Email: participant.email,
          Telefone: participant.phone,
        };
        datasCheckin.map((dt) => {
          objRow[[dt]] = isCheckin(participant, dt) ? "SIM" : "NÃO";
        });

        // console.log(objRow);

        worksheet.addRow(objRow);
      });

      // Write to file
      if (Platform.OS == "web") {
        workbook.xlsx
          .writeBuffer({
            base64: true,
          })
          .then((xls64) => {
            var a = document.createElement("a");
            var data = new Blob([xls64], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            var url = URL.createObjectURL(data);
            a.href = url;
            a.download = "checkins.xlsx";
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            }, 0);
            resolve(fileUri);
          });
      } else {
        workbook.xlsx.writeBuffer().then((buffer) => {
          // Do this to use base64 encoding
          const nodeBuffer = NodeBuffer.from(buffer);
          const bufferStr = nodeBuffer.toString("base64");
          FileSystem.writeAsStringAsync(fileUri, bufferStr, {
            encoding: FileSystem.EncodingType.Base64,
          }).then(() => {
            resolve(fileUri);
          });
        });
      }
    });
  };

  const shareExcel = async () => {
    const shareableExcelUri = await generateShareableExcel();
    if (Platform.OS != "web") {
      Sharing.shareAsync(shareableExcelUri, {
        mimeType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Android
        dialogTitle: "Your dialog title here", // Android and Web
        UTI: "com.microsoft.excel.xlsx", // iOS
      })
        .catch((error) => {
          console.error("Error", error);
        })
        .then(() => {
          console.log("Return from sharing dialog");
        });
    }
  };

  const fetchParticipants = async () => {
    try {
      setLoadingParticipants(true);
      setMsgErrorParticipants(null);

      const { data } = await api.get(
        `/events/${event.id}/participants-all?status=Concluded`
      );
      const newParticipants = data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      setParticipants(newParticipants);
    } catch (error) {
      new SentryHandler(error);
    } finally {
      setLoadingParticipants(false);
    }
  };

  const renderParticipants = () => {
    if (loadingParticipants) {
      return (
        <View style={{ height: 40, marginVertical: 40 }}>
          <ActivityIndicator color={colors.primary} size="large" />
        </View>
      );
    }

    if (participants && !participants.length) {
      return (
        <Text style={{ textAlign: "center", marginTop: 20, fontSize: 20 }}>
          Nenhum participante encontrado para este evento.
        </Text>
      );
    }

    return participants.map((participant, index) => {
      return (
        <ItemHistory key={index} participant={participant} event={event} />
      );
    });
  };

  return (
    <>
      <MainLayout>
        <View style={{ paddingVertical: 20, paddingHorizontal: 16, flex: 1 }}>
          <Text
            style={{
              fontSize: 18,
              color: colors.primary,
              padding: 20,
              fontWeight: "bold",
            }}
          >
            Participantes
          </Text>

          <ScrollView>{renderParticipants()}</ScrollView>

          <Button
            mode="contained"
            style={{
              marginTop: 30,
              marginBottom: 20,
              height: 45,
              justifyContent: "center",
              width: "100%",
            }}
            onPress={shareExcel}
            // loading={fetchingEvents}
            disabled={loadingParticipants || !participants.length}
            color={colors.primary}
          >
            <Text style={{ color: "#fff" }}>Salvar Arquivo</Text>
          </Button>
        </View>
      </MainLayout>
    </>
  );
}
