import { Platform } from "react-native";
import * as Sentry from "sentry-expo";

export default class SentryHandler {
  constructor(error) {
    if (process.env.REACT_APP_LOCAL === "production") {
      const isWeb = Platform.OS === "web";
      Sentry.init({
        dsn: "https://0aeb7c7fc87b4c6f9cdc186646f85cdd@o4504180579172352.ingest.sentry.io/4505075866927104",
        enableInExpoDevelopment: true,
        debug: true,
        enableNative: false,
      });

      if (isWeb) {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.Native.captureException(error);
      }
    }
  }
}
