import React from "react";
import Routers from "./src/router";
import { DefaultTheme, Provider as PaperProvider } from "react-native-paper";
import { Platform } from "react-native";
import AppProvider from "./src/hooks";
import * as ScreenOrientation from "expo-screen-orientation";

const theme = {
  ...DefaultTheme,
  roundness: 10,
  colors: {
    ...DefaultTheme.colors,
    primary: "#005EB8",
    accent: "#FFF",
    success: "#00AE06",
    danger: "#E10000",
    underlineColor: "transparent",
  },
};

export default function App() {
  React.useEffect(() => {
    const isWeb = Platform.OS === "web";
    if (!isWeb) {
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
    }
  }, []);

  return (
    <PaperProvider theme={theme}>
      <AppProvider>
        <Routers />
      </AppProvider>
    </PaperProvider>
  );
}
