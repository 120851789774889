import { Button } from "react-native-paper";
import { StyleSheet, Text } from "react-native";
import { withTheme } from "react-native-paper";

function AppButton({ text, loading = false, onPress, theme }) {
  return (
    <Button
      mode="contained"
      style={style.button}
      loading={loading}
      disabled={loading}
      onPress={onPress}
    >
      <Text style={{ color: theme.colors.accent, fontWeight: "bold" }}>
        {text}
      </Text>
    </Button>
  );
}

export default withTheme(AppButton);

const style = StyleSheet.create({
  button: {
    marginTop: 14,
  },
});
