import { createContext, useContext, useState } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { useTheme, Portal, Dialog } from "react-native-paper";

const DialogLoaderContext = createContext();

const DialogLoaderProvider = ({ children }) => {
  const { colors, roundness } = useTheme();
  const [showDialog, setShowDialog] = useState(false);
  const [title, setTitle] = useState(null);

  const show = (title = "Carregando...") => {
    setTitle(title);
    setShowDialog(true);
  };

  const hide = () => {
    setShowDialog(false);
  };

  return (
    <DialogLoaderContext.Provider
      value={{
        show,
        hide,
      }}
    >
      {children}

      <Portal>
        <Dialog
          visible={showDialog}
          onDismiss={hide}
          dismissable={false}
          style={{
            width: 350,
            alignSelf: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              height: 25,
              backgroundColor: colors.warning,
              justifyContent: "center",
              alignItems: "center",
              borderTopEndRadius: roundness,
              borderTopStartRadius: roundness,
              marginTop: 20,
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              {title}
            </Text>
          </View>

          <View style={{ height: 20, marginVertical: 40 }}>
            <ActivityIndicator color={colors.primary} size="large" />
          </View>
        </Dialog>
      </Portal>
    </DialogLoaderContext.Provider>
  );
};

const useDialogLoader = () => {
  const context = useContext(DialogLoaderContext);

  if (!context) {
    throw new Error("useDialogLoader não possuí context");
  }

  return context;
};

export { DialogLoaderProvider, useDialogLoader };
