import { View } from "react-native";
import { useTheme } from "react-native-paper";

function MainLayout(props) {
  const { colors } = useTheme();

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.primary,
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: "#fff",
          marginTop: 30,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        {props.children}
      </View>
    </View>
  );
}

export default MainLayout;
