import React, { useEffect } from "react";
import { Modal, View, Text } from "react-native";
import GlobalStyles from "../../styles";
import { expo } from "../../app.json";
import Storage from "../utils/storage";
import { IconButton, useTheme, Button } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";

export default function ModalLogout({ visible, onPressClose }) {
  const nav = useNavigation();

  useEffect(() => {
    Storage.deleteData();

    nav.reset({
      index: 0,
      routes: [{ name: "Login" }],
    });
  }, []);

  return <View></View>;
}
