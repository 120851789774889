import { Text, View, Platform } from "react-native";
import React, { useEffect, useState } from "react";
import { useTheme, IconButton, Avatar } from "react-native-paper";

const isWeb = Platform.OS === "web";

export default function ItemHistory({ participant, event }) {
  const { colors, roundness } = useTheme();
  const [daysCheckin, setDaysCheckin] = useState([]);

  useEffect(() => {
    const start = new Date(FormataStringData(event.start));
    const finish = new Date(FormataStringData(event.finish));
    const finalFinishDate = eventNotFinished(finish) ? new Date() : finish;

    const rangeDates = getDaysArray(start, finalFinishDate);

    setDaysCheckin(rangeDates);
  }, [event]);

  const eventNotFinished = (end) => {
    const today = new Date();
    return today.getTime() < end.getTime();
  };

  const FormataStringData = (data) => {
    // Formato que está vindo: dd/mm/yyy 08:00
    const [dateFull] = data.split(" ");
    return dateFull.split("/").reverse().join("-");
  };

  const isCheckin = (data) => {
    const find = participant.historico_checkin.filter((day) => {
      let [dateFull] = day.data_checkin.split(" ");
      dateFull = dateFull.split("-").join("/");
      const finalDate = new Date(dateFull);
      finalDate.setHours(0, 0, 0, 0);
      data.setHours(0, 0, 0, 0);

      return finalDate.getTime() === data.getTime();
    });
    return find.length > 0 ? true : false;
  };

  const getDaysArray = (start, end) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const getParsedDate = (date) => {
    // const [newDate] = date.split("T");
    // console.log("getParsedDate");
    // console.log(date.getDate());

    var dd = date.getUTCDate();
    var mm = date.getUTCMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = dd + "/" + mm;
    return date.toString();
  };

  const getParsedDateString = (strDate) => {
    var strSplitDate = String(strDate).split(" ");
    var date = new Date(strSplitDate[0]);

    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = dd + "/" + mm;
    return date.toString();
  };

  const hasHistory = () => {
    if (participant.historico_checkin.length) {
      return (
        <View style={{ flex: 1 }}>
          <Text style={{ textAlign: "center", marginTop: 20, fontSize: 14 }}>
            Nenhum historico encontrado para este evento.
          </Text>
        </View>
      );
    }

    return <View></View>;
  };

  return (
    <View
      style={
        isWeb
          ? {
              padding: 16,
              marginBottom: 10,
              backgroundColor: "#F5F6FA",
            }
          : {
              paddingVertical: 20,
              paddingHorizontal: 10,
              marginBottom: 10,
              backgroundColor: "#F5F6FA",
            }
      }
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Avatar.Image
          size={45}
          source={{
            uri: event.cover.square,
          }}
        />
        <View style={{ marginLeft: 10 }}>
          <Text
            style={{
              flexShrink: 1,
              marginTop: 4,
              fontWeight: "bold",
              color: colors.primary,
            }}
          >
            {participant.name}
          </Text>
          <Text style={{ marginTop: 4 }}>{participant.cpf}</Text>
        </View>
      </View>

      <View
        style={{
          borderBottomWidth: 2,
          borderColor: colors.primary,
          width: "100%",
          marginVertical: 8,
          borderRadius: roundness,
        }}
      />

      <View
        style={{
          flexDirection: "row",
        }}
      >
        {daysCheckin.map((day, index) => {
          if (index <= 6) {
            if (isCheckin(day)) {
              return (
                <View key={index} style={{ marginLeft: 8 }}>
                  <Text style={{ color: colors.primary, marginBottom: 4 }}>
                    {getParsedDate(day)}
                  </Text>

                  <View
                    style={{
                      backgroundColor: colors.success,
                      alignItems: "center",
                      justifyContent: "center",
                      width: 40,
                      height: 40,
                      borderRadius: roundness,
                    }}
                  >
                    <Text>
                      <IconButton icon="check" color={colors.accent} />
                    </Text>
                  </View>
                </View>
              );
            } else {
              return (
                <View key={index} style={{ marginLeft: 8 }}>
                  <Text style={{ color: colors.primary, marginBottom: 4 }}>
                    {getParsedDate(day)}
                  </Text>

                  <View
                    style={{
                      backgroundColor: colors.danger,
                      alignItems: "center",
                      justifyContent: "center",
                      width: 40,
                      height: 40,
                      borderRadius: roundness,
                    }}
                  >
                    <Text>
                      <IconButton icon="close" color={colors.accent} />
                    </Text>
                  </View>
                </View>
              );
            }
          }
        })}

        {hasHistory()}
      </View>
    </View>
  );
}
