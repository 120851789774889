import { Button, useTheme, Portal, Dialog, Avatar } from "react-native-paper";
import { Text, View } from "react-native";

function DialogAlert({
  error = false,
  hide,
  show,
  successMessage = "",
  errorMessage = "",
}) {
  const { colors, roundness } = useTheme();

  const getStyleHeaderDialog = () => {
    return {
      width: "100%",
      height: 60,
      backgroundColor: error ? colors.danger : colors.success,
      justifyContent: "center",
      alignItems: "center",
      borderTopEndRadius: roundness,
      borderTopStartRadius: roundness,
    };
  };

  return (
    <Portal>
      <Dialog
        visible={show}
        onDismiss={hide}
        style={{
          width: 350,
          alignSelf: "center",
        }}
      >
        <View style={getStyleHeaderDialog()}>
          <Avatar.Icon
            icon={error ? require('../assets/alert-circle.svg') : require('../assets/check.svg')}
            size={60}
            color="#fff"
            style={{ backgroundColor: "none" }}
          />
        </View>

        <Text style={{ marginVertical: 30, textAlign: "center" }}>
          {errorMessage || successMessage}
        </Text>
        <Dialog.Actions>
          <Button color={colors.primary} onPress={hide}>
            OK
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
}

export default DialogAlert;
