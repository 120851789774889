import { Text } from "react-native";
import { withTheme } from "react-native-paper";

function Error({ error = null, theme }) {
  return (
    <>
      {error && (
        <Text
          style={{
            color: theme.colors.error,
            fontWeight: "bold",
            marginBottom: 10,
            marginTop: 10,
            fontSize: 14,
            textAlign: "center",
          }}
        >
          {error}
        </Text>
      )}
    </>
  );
}

export default withTheme(Error);
