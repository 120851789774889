import { TextInput } from "react-native-paper";
import { StyleSheet, Text } from "react-native";

function TextField({
  label,
  value,
  setValue,
  error = null,
  hideText = false,
  ...props
}) {
  return (
    <>
      <TextInput
        value={value}
        mode="outlined"
        secureTextEntry={hideText}
        underlineColor="transparent"
        onChangeText={(text) => setValue(text)}
        {...props}
      />
      {error && <Text style={styles.err}>{error}</Text>}
    </>
  );
}

const styles = StyleSheet.create({
  err: {
    color: "#DB324D",
    fontWeight: "bold",
    marginBottom: 2,
    fontSize: 12,
  },
});

export default TextField;
