import AsyncStorage from "@react-native-async-storage/async-storage";

class Storage {
  constructor() {
    this.USER = "@USER_DATA";
  }

  setData = async ({ token, user }) => {
    return AsyncStorage.setItem(
      this.USER,
      JSON.stringify({
        token,
        ...user,
      })
    );
  };

  getToken = async () => {
    const data = await AsyncStorage.getItem(this.USER);
    return data == null ? null : JSON.parse(data).token;
  };

  getData = async () => {
    const data = await AsyncStorage.getItem(this.USER);
    return JSON.parse(data);
  };

  getTypeUser = async () => {
    const data = await AsyncStorage.getItem(this.USER);
    return data === null ? null : JSON.parse(data).type;
  };

  deleteData = async () => {
    return await AsyncStorage.removeItem(this.USER);
  };

  clear = () => {
    return AsyncStorage.clear();
  };
}

export default new Storage();
