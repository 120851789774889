import { View, Text, TouchableOpacity } from "react-native";
import { useTheme, Button } from "react-native-paper";

function CardOptions({
  icon,
  title,
  mode = "primary",
  onPress,
  disabled = false,
}) {
  const { colors } = useTheme();

  const getViewByMode = () => {
    if (mode === "primary") {
      return (
        <View
          style={{
            backgroundColor: colors.primary,
            borderRadius: 10,
            paddingHorizontal: 6,
            paddingVertical: 16,
            marginBottom: 14,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Button
              icon={icon}
              color={colors.accent}
              labelStyle={{ fontSize: 40 }}
              onPress={disabled ? null : onPress}
            />

            <Text
              style={{ color: colors.accent, fontWeight: "bold", fontSize: 16 }}
            >
              {title}
            </Text>
          </View>
          <Button
            onPress={disabled ? null : onPress}
            icon={require("../assets/arrow-circle-right.svg")}
            color={colors.accent}
            labelStyle={{ fontSize: 20 }}
          />
        </View>
      );
    }

    return (
      <View
        style={{
          backgroundColor: colors.accent,
          borderRadius: 10,
          borderColor: colors.primary,
          borderWidth: 1,
          paddingHorizontal: 6,
          paddingVertical: 16,
          marginBottom: 20,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Button
            icon={icon}
            color={colors.primary}
            labelStyle={{ fontSize: 40 }}
            onPress={disabled ? null : onPress}
          />

          <Text
            style={{ color: colors.primary, fontWeight: "bold", fontSize: 16 }}
          >
            {title}
          </Text>
        </View>
        <Button
          onPress={disabled ? null : onPress}
          icon={require("../assets/arrow-circle-right.svg")}
          color={colors.primary}
          labelStyle={{ fontSize: 20 }}
        />
      </View>
    );
  };

  return (
    <TouchableOpacity
      onPress={disabled ? null : onPress}
      style={{ opacity: disabled ? 0.6 : 1 }}
    >
      {getViewByMode()}
    </TouchableOpacity>
  );
}

export default CardOptions;
