import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  FlatList,
  Keyboard,
  Platform,
} from "react-native";
import { Badge, useTheme, TextInput, Button } from "react-native-paper";
import { Masks, useMaskedInputProps } from "react-native-mask-input";

import MainLayout from "../../layouts/MainLayout";
import GlobalStyles from "../../../styles";
import useForm from "../../hooks/useForm";
import { useDialogConfirm } from "../../hooks/useDialogConfirm";

import api from "../../services/api";
import {
  getCurrentDate,
  handleHttpError,
  serializeQueryString,
} from "../../utils";
import ItemEventSelectable from "../../components/ItemEventSelectable";
import SentryHandler from "../../utils/sentry_handler";

const CURRENT_DATE = getCurrentDate();

const QUERY_STRING = {
  start: CURRENT_DATE,
  status: "Active",
  order_by: "date_desc",
  limit: 10,
  paginate: false,
};

const isWeb = Platform.OS === "web";

export default function RegisterUser({ route, navigation }) {
  const { colors } = useTheme();
  const { onRefresh } = route.params;

  const name = useForm(["required"]);
  const company = useForm(["required"]);
  const cpf = useForm(["required", "cpf"]);
  const phone = useForm(["required", "phone"]);
  const dialogConfirm = useDialogConfirm();

  const [searchValue, setSearchValue] = useState("");
  const [eventsSelected, setEventsSelected] = useState([]);
  const [currentIndexStep, setCurrentIndexStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [runRequest, setRunRequest] = useState(false);
  const [events, setEvents] = useState([]);
  const [steps, setSteps] = useState([
    {
      title: "Inserir dados",
      active: true,
    },
    {
      title: "Selecionar eventos",
      active: false,
    },
  ]);

  const maskCpf = useMaskedInputProps({
    value: cpf.value,
    onChangeText: cpf.onChange,
    mask: Masks.BRL_CPF,
  });

  const maskPhone = useMaskedInputProps({
    value: phone.value,
    onChangeText: phone.onChange,
    mask: Masks.BRL_PHONE,
  });

  useEffect(() => {
    setHeader();
  }, [currentIndexStep]);

  useEffect(() => {
    fetchEvents();
  }, [runRequest]);

  const setHeader = () => {
    navigation.setOptions({
      title: "Cadastrar usuário",
      headerLeft: (props) => {
        return (
          <Button
            icon="chevron-left"
            color="#fff"
            labelStyle={{ fontSize: 40 }}
            onPress={() => {
              if (currentIndexStep === 0) {
                navigation.navigate("UsersPage");
              } else {
                setCurrentIndexStep(0);
              }
            }}
          />
        );
      },
    });
  };

  const handleClick = async () => {
    if (currentIndexStep === 0) {
      if (
        cpf.isValid() &&
        name.isValid() &&
        company.isValid() &&
        phone.isValid()
      ) {
        steps[1].active = true;
        setSteps(steps);
        setCurrentIndexStep(1);
      }

      return;
    }

    if (!eventsSelected.length) {
      isWeb
        ? alert("É obrigatório selecionar pelo menos um evento para continuar")
        : dialogConfirm.show({
            title: "Erro de validação",
            message:
              "É obrigatório selecionar pelo menos um evento para continuar",
            showCancelButton: false,
          });
      return;
    }

    const payload = {
      name: name.value,
      login: cpf.value,
      cpf: cpf.value,
      type: "externo",
      phone: phone.value,
      company: company.value,
    };

    try {
      setIsSaving(true);
      const { data } = await api.post("/users", payload);
      await api.post(`/users/${data.id}/events`, { events: eventsSelected });
      navigation.navigate("UsersPage", {});
    } catch (error) {
      alert(handleHttpError(error));
      new SentryHandler(error);
    } finally {
      onRefresh();
      setIsSaving(false);
    }
  };

  const formStep1 = () => {
    return (
      <View>
        <TextInput
          mode="outlined"
          label="CPF"
          placeholder="Digite o CPF"
          style={{ marginTop: 10 }}
          onBlur={cpf.onBlur}
          error={cpf.error}
          {...maskCpf}
          keyboardType="numeric"
          loading={true}
        />
        {!!cpf.error && (
          <Text style={GlobalStyles.errorValidation}>{cpf.error}</Text>
        )}

        <TextInput
          mode="outlined"
          label="Nome"
          placeholder="Digite o nome"
          value={name.value}
          error={name.error}
          onChangeText={name.onChange}
          onBlur={name.onBlur}
        />
        {!!name.error && (
          <Text style={GlobalStyles.errorValidation}>{name.error}</Text>
        )}

        <TextInput
          mode="outlined"
          label="Empresa"
          placeholder="Digite o nome"
          value={company.value}
          error={company.error}
          onChangeText={company.onChange}
          onBlur={company.onBlur}
        />
        {!!company.error && (
          <Text style={GlobalStyles.errorValidation}>{company.error}</Text>
        )}
        <TextInput
          mode="outlined"
          label="Telefone"
          placeholder="Digite o telefone"
          onBlur={phone.onBlur}
          error={phone.error}
          {...maskPhone}
          keyboardType="numeric"
        />
        {!!phone.error && (
          <Text style={GlobalStyles.errorValidation}>{phone.error}</Text>
        )}
      </View>
    );
  };

  const getIconRight = () => {
    if (clearSearch) {
      return (
        <TextInput.Icon
          icon={require("../../assets/close.svg")}
          color={colors.primary}
          onPress={() => {
            setClearSearch(false);
            setSearchValue("");
            setEvents([]);
            setRunRequest(!runRequest);
            Keyboard.dismiss();
          }}
        />
      );
    }

    return (
      <TextInput.Icon
        icon={require("../../assets/search.svg")}
        onPress={() => {
          setEvents([]);
          setClearSearch(true);
          setRunRequest(!runRequest);
        }}
        color={colors.primary}
      />
    );
  };

  const formStep2 = () => {
    return (
      <View style={{ height: "78%" }}>
        <TextInput
          placeholder="Buscar por nome ou código..."
          mode="flat"
          underlineColor="transparent"
          dense={true}
          style={GlobalStyles.textField}
          value={searchValue}
          onChangeText={(value) => setSearchValue(value)}
          right={getIconRight()}
          onSubmitEditing={() => {
            setClearSearch(true);
            setRunRequest(!runRequest);
            setEvents([]);
          }}
        />

        {isLoadingEvents && (
          <View style={{ height: 40, marginVertical: 40 }}>
            <ActivityIndicator color={colors.primary} size="large" />
          </View>
        )}

        {!isLoadingEvents && (
          <FlatList
            data={events}
            renderItem={({ item }) => (
              <ItemEventSelectable
                item={item}
                onPress={(eventItem) => {
                  if (eventItem.checked) {
                    const [dateFull] = eventItem.start.split(" ");
                    const date = dateFull.split("/").reverse().join("-");
                    eventsSelected.push({
                      code: item.code,
                      start: date,
                    });
                    setEventsSelected(eventsSelected);
                  } else {
                    const selecteds = eventsSelected.filter(
                      (item) => String(item.code) !== String(eventItem.code)
                    );

                    setEventsSelected(selecteds);
                  }
                }}
              />
            )}
            keyExtractor={(item, index) => `${item.id}-${index}`}
            onEndReachedThreshold={0.1}
          />
        )}
      </View>
    );
  };

  const buildBody = () => {
    const forms = {
      0: () => formStep1(),
      1: () => formStep2(),
    };
    return forms[currentIndexStep]();
  };

  const getTitleButton = () => {
    if (isSaving) return "Salvando...";

    if (currentIndexStep === 0) return "Próximo";

    return "Finalizar";
  };

  const fetchEvents = async () => {
    try {
      setIsLoadingEvents(true);

      const queryObj = {
        ...QUERY_STRING,
        like: searchValue,
      };

      const queryString = serializeQueryString(queryObj);
      const { data } = await api.get(`/events${queryString}`);

      setEvents([
        ...events,
        ...data.map((item) => ({
          ...item,
          checked: eventsSelected.filter(
            (code) => String(code) !== String(item.code)
          ).length,
        })),
      ]);
    } catch (error) {
      new SentryHandler(error);
    } finally {
      setIsLoadingEvents(false);
    }
  };

  return (
    <MainLayout>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F5F6FA",
          paddingHorizontal: 14,
          paddingVertical: 20,
          borderTopEndRadius: 10,
          borderTopStartRadius: 10,
          borderBottomEndRadius: 10,
          borderBottomStartRadius: 10,
          marginHorizontal: 8,
          marginTop: 16,
        }}
      >
        {steps.map((item, index) => {
          return (
            <View
              style={{ flexDirection: "row", marginHorizontal: 20 }}
              key={item.title}
            >
              <Badge
                style={{
                  backgroundColor: item.active ? colors.primary : "#a8a9ac",
                  color: "#fff",
                }}
              >
                {index + 1}
              </Badge>
              <Text
                style={{
                  marginLeft: 4,
                  color: item.active ? colors.primary : "#000",
                }}
              >
                {item.title}
              </Text>
            </View>
          );
        })}
      </View>

      <View style={{ padding: 10 }}>
        {buildBody()}
        <Button
          mode="contained"
          disabled={isSaving}
          style={{
            marginTop: 10,
            marginBottom: 20,
            height: 45,
            justifyContent: "center",
            width: "100%",
          }}
          onPress={handleClick}
          color={colors.primary}
        >
          <Text style={{ color: colors.accent }}>{getTitleButton()}</Text>
        </Button>
      </View>
    </MainLayout>
  );
}
