import { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, ScrollView } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  Button,
  useTheme,
  TextInput,
  Switch,
  Menu,
  Avatar,
  ActivityIndicator,
} from "react-native-paper";
import { Masks, useMaskedInputProps } from "react-native-mask-input";
import { useNavigation } from "@react-navigation/native";
import { useDialogLoader } from "../../hooks/useDialogLoader";
import api from "../.././services/api";
import GlobalStyles from "../../../styles";
import useForm from "../../hooks/useForm";
import MainLayout from "../../layouts/MainLayout";
import { useDialogResultMessage } from "../../hooks/useDialogResultMessage";
import {
  validateCnpj,
  handleHttpError,
  phoneMasked,
  cnpjMasked,
} from "../../utils";
import SentryHandler from "../../utils/sentry_handler";

const TYPES = [
  {
    title: "CNPJ",
    value: "Cadastro Nacional de Pessoa Jurídica",
  },
  {
    title: "Cod. Produtor Rural",
    value: "Número do Imóvel Rural na Receita Federal",
  },
  {
    title: "DAP",
    value: "Declaração de Aptidão do Pronaf",
  },
  {
    title: "NIRF",
    value: "Inscrição Estadual Produtor Rural",
  },
];

function QuickSubscription({ route }) {
  const { event, onRefresh } = route.params;
  const { colors, roundness } = useTheme();
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const name = useForm(["required"]);
  const cpf = useForm(["required", "cpf"]);
  const email = useForm(["required", "email"]);
  const phone = useForm(["required", "phone"]);
  const type = useForm(["required"]);
  const document = useForm(["required"]);
  const nameCompany = useForm(["required"]);
  const loader = useDialogLoader();
  const [hasCompany, setHasCompany] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [isSearchingCpf, setIsSearchingCpf] = useState(false);
  const [showFormPersonal, setShowFormPersonal] = useState(false);
  const [searchedDocument, setSearchedDocument] = useState(false);

  const dialogResultMessage = useDialogResultMessage();

  useEffect(() => {
    navigation.setOptions({
      title: event.name,
    });
  }, []);

  const searchCustomerByDocument = async () => {
    try {
      setIsSearchingCpf(true);
      const { data } = await api.get(
        `/customers/${cpf.value.replace(/\D/g, "")}`
      );
      setSearchedDocument(true);
      if (!data.message) {
        name.setValue(data.name);
        email.setValue(data.email);
        phone.setValue(
          data.phone ? phoneMasked(data.phone.replace(/\D/g, "")) : ""
        );
        searchCompanyByCustomerId(data.id);
      }
    } catch (e) {
      new SentryHandler(e);
    } finally {
      setIsSearchingCpf(false);
      setShowFormPersonal(true);
    }
  };

  const searchCompanyByCustomerId = async (customer_id) => {
    try {
      const { data } = await api.get(`/companies/${customer_id}`);

      if (!data.data) return;

      const company = data.data[data.data.length - 1];

      const idx = TYPES.findIndex((item) => item.value === company.type.trim());

      if (idx != undefined) {
        type.setValue(idx);
        if (idx === 0) {
          document.setValue(cnpjMasked(company.document));
        } else {
          document.setValue(company.document);
        }
      }

      nameCompany.setValue(company.name.trim());
    } catch (e) {}
  };

  useEffect(() => {
    if (!hasCompany) {
      type.setError(null);
      type.setValue("");
      nameCompany.setError(null);
      nameCompany.setValue("");
    }
  }, [hasCompany]);

  const maskCpf = useMaskedInputProps({
    value: cpf.value,
    onChangeText: cpf.onChange,
    mask: Masks.BRL_CPF,
  });

  const maskPhone = useMaskedInputProps({
    value: phone.value,
    onChangeText: phone.onChange,
    mask: Masks.BRL_PHONE,
  });

  const maskCnpj = useMaskedInputProps({
    value: document.value,
    onChangeText: document.onChange,
    mask: Masks.BRL_CNPJ,
  });

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  const handleSubmit = () => {
    if (name.isValid() === false || cpf.isValid() === false) {
      return;
    }

    if (email.isValid() === false || phone.isValid() === false) {
      return;
    }

    if (
      hasCompany &&
      (nameCompany.isValid() === false ||
        type.isValid() === false ||
        document.isValid() === false)
    ) {
      return;
    }

    if (hasCompany && !validateCnpj(document.value)) {
      document.setError("CNPJ inválido");
      return;
    }

    const company = {
      document: "",
      name: "",
      type: "",
    };

    if (hasCompany) {
      company.document = document.value;
      company.name = nameCompany.value;
      company.type = TYPES[type.value].value;
    }

    const payload = {
      call_id: "BFF-APP",
      contract_id: 0,
      origin_id: process.env.REACT_APP_ORIGIN_ID,
      has_company: hasCompany,
      event_id: event.id,
      name: name.value,
      cpf: cpf.value,
      email: email.value,
      phone: phone.value,
      company,
    };

    sendSubscription(payload);
  };

  const sendSubscription = async (payload) => {
    try {
      loader.show("Enviando...");
      setIsPosting(true);
      await api.post(`/events/${event.id}/participants`, payload);
      loader.hide();
      onRefresh();
      dialogResultMessage.show({
        error: false,
        message: "Cadastro e Checkin realizado com sucesso!",
      });
      navigation.goBack();
    } catch (error) {
      dialogResultMessage.show({
        error: true,
        message: handleHttpError(error),
      });
      new SentryHandler(error);
    } finally {
      setIsPosting(false);
      loader.hide();
    }
  };

  const onChangeHasCompany = () => setHasCompany(!hasCompany);

  return (
    <MainLayout>
      <ScrollView>
        <View style={{ padding: 10, flex: 1 }}>
          <Text
            style={{
              fontSize: 16,
              color: colors.primary,
              fontWeight: "bold",
              marginBottom: 16,
            }}
          >
            Inscrição rápida
          </Text>

          <Text
            style={{ fontSize: 14, color: colors.primary, marginVertical: 10 }}
          >
            Dados pessoais
          </Text>
          <TextInput
            mode="outlined"
            label="CPF"
            placeholder="Digite o CPF"
            style={{ marginTop: 10 }}
            onBlur={() => {
              cpf.onBlur();
              if (!cpf.isValid()) {
                setSearchedDocument(false);
                // searchCustomerByDocument();
              }
            }}
            error={cpf.error}
            {...maskCpf}
            keyboardType="numeric"
            loading={true}
          />
          {!!cpf.error && (
            <Text style={GlobalStyles.errorValidation}>{cpf.error}</Text>
          )}
          {isSearchingCpf && (
            <View style={{ height: 30, marginVertical: 30 }}>
              <ActivityIndicator color={colors.primary} />
            </View>
          )}
          {showFormPersonal && (
            <>
              <TextInput
                mode="outlined"
                label="Nome"
                placeholder="Digite o nome"
                value={name.value}
                error={name.error}
                onChangeText={name.onChange}
                onBlur={name.onBlur}
              />
              {!!name.error && (
                <Text style={GlobalStyles.errorValidation}>{name.error}</Text>
              )}

              <Text
                style={{
                  fontSize: 14,
                  color: colors.primary,
                  marginVertical: 10,
                }}
              >
                Dados de contato
              </Text>

              <TextInput
                mode="outlined"
                label="Email"
                placeholder="Digite o email"
                value={email.value}
                error={email.error}
                autoCapitalize="none"
                onChangeText={email.onChange}
                onBlur={email.onBlur}
                keyboardType="email-address"
              />
              {!!email.error && (
                <Text style={GlobalStyles.errorValidation}>{email.error}</Text>
              )}
              <TextInput
                mode="outlined"
                label="Telefone"
                placeholder="Digite o telefone"
                style={{ marginTop: 10 }}
                onBlur={phone.onBlur}
                error={phone.error}
                {...maskPhone}
                keyboardType="numeric"
              />
              {!!phone.error && (
                <Text style={GlobalStyles.errorValidation}>{phone.error}</Text>
              )}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginVertical: 10,
                }}
              >
                <Text style={{ color: colors.primary, fontWeight: "bold" }}>
                  Representa uma empresa?
                </Text>
                <Switch
                  value={hasCompany}
                  onValueChange={onChangeHasCompany}
                  color={colors.primary}
                />
              </View>
            </>
          )}

          {hasCompany && (
            <View>
              <Text
                style={{
                  fontSize: 14,
                  color: colors.primary,
                  marginVertical: 10,
                }}
              >
                Dados da empresa
              </Text>
              <TextInput
                mode="outlined"
                label="Nome da empresa"
                disabled={!hasCompany}
                value={nameCompany.value}
                onChangeText={nameCompany.onChange}
                error={nameCompany.error}
                onBlur={hasCompany ? nameCompany.onBlur : null}
              />
              {!!nameCompany.error && (
                <Text style={GlobalStyles.errorValidation}>
                  {nameCompany.error}
                </Text>
              )}
              <Menu
                visible={visible}
                onDismiss={closeMenu}
                disabled={!hasCompany}
                anchor={
                  <TouchableOpacity
                    onPress={openMenu}
                    disabled={!hasCompany}
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      borderWidth: 1,
                      borderColor: colors.backdrop,
                      paddingHorizontal: 10,
                      paddingVertical: 16,
                      borderRadius: roundness,
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        opacity: !hasCompany ? 0.4 : 1,
                      }}
                    >
                      <Text>Tipo de documento</Text>

                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text style={{ marginRight: 10 }}>
                          {type.value !== "" && type.value !== undefined
                            ? TYPES[type.value] !== undefined
                              ? TYPES[type.value].title
                              : ""
                            : "Selecionar tipo"}
                        </Text>
                        <Avatar.Icon
                          icon={require("../../assets/chevron-down.svg")}
                          size={25}
                          color="#000"
                          style={{ backgroundColor: "none" }}
                        />
                      </View>
                    </View>
                    {!!type.error && (
                      <Text style={GlobalStyles.errorValidation}>
                        {type.error}
                      </Text>
                    )}
                  </TouchableOpacity>
                }
              >
                {TYPES.map(({ title }, index) => (
                  <Menu.Item
                    onPress={() => {
                      type.setValue(index);
                      setTimeout(() => {
                        document.setValue("");
                        document.setError(null);
                      }, 500);
                      closeMenu();
                    }}
                    title={title}
                    key={title}
                  />
                ))}
              </Menu>
              {type.value === 0 ? (
                <View>
                  <TextInput
                    label="CNPJ"
                    placeholder="Digite o CNPJ"
                    mode="outlined"
                    error={document.error}
                    keyboardType="numeric"
                    onBlur={() => {
                      document.onBlur();
                      if (!validateCnpj(document.value)) {
                        document.setError("CNPJ inválido");
                      } else {
                        document.setError(null);
                      }
                    }}
                    {...maskCnpj}
                  />
                  {!!document.error && (
                    <Text style={GlobalStyles.errorValidation}>
                      {document.error}
                    </Text>
                  )}
                </View>
              ) : null}
              {type.value !== "" &&
              type.value !== 0 &&
              type.value !== undefined ? (
                <View>
                  <TextInput
                    label={
                      type.value !== undefined
                        ? TYPES[type.value] !== undefined
                          ? TYPES[type.value].title
                          : ""
                        : ""
                    }
                    placeholder="Digite documento"
                    mode="outlined"
                    error={document.error}
                    onBlur={document.onBlur}
                    onChangeText={document.onChange}
                    value={document.value}
                  />
                  {!!document.error && (
                    <Text style={GlobalStyles.errorValidation}>
                      {document.error}
                    </Text>
                  )}
                </View>
              ) : null}
            </View>
          )}
        </View>
      </ScrollView>

      <Button
        mode="contained"
        style={{ marginBottom: insets.bottom + 4, marginHorizontal: 10 }}
        contentStyle={{ height: 50 }}
        loading={isPosting || isSearchingCpf}
        disabled={isPosting || isSearchingCpf}
        onPress={() =>
          searchedDocument ? handleSubmit() : searchCustomerByDocument()
        }
      >
        <Text style={{ color: colors.accent }}>
          {searchedDocument
            ? isPosting
              ? "Inscrevendo..."
              : "Inscrever e entrar"
            : "Buscar CPF"}
        </Text>
      </Button>
    </MainLayout>
  );
}

export default QuickSubscription;
