"use strict";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  containerCenter: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  title: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#fff",
    alignSelf: "center",
  },
  titleHeader: { fontSize: 18, fontWeight: "bold" },
  errorValidation: {
    color: "#DB324D",
    fontWeight: "bold",
    marginBottom: 2,
    fontSize: 12,
  },

  textField: {
    borderTopEndRadius: 10,
    borderTopStartRadius: 10,
    borderBottomEndRadius: 10,
    borderBottomStartRadius: 10,
    marginBottom: 4,
    height: 50,
    borderBottomWidth: 0,
  },
});
