import {
  View,
  Text,
  Keyboard,
  ActivityIndicator,
  FlatList,
} from "react-native";
import { useTheme, TextInput } from "react-native-paper";
import CardOptions from "../../components/CardOptions";
import ItemUser from "../../components/ItemUser";
import GlobalStyle from "../../../styles";
import MainLayout from "../../layouts/MainLayout";
import { useEffect, useState, useRef } from "react";
import { handleHttpError, serializeQueryString } from "../../utils";
import api from "../../services/api";

function UsersPage({ navigation }) {
  const { colors } = useTheme();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [runRequest, setRunRequest] = useState(false);
  const [runRequestLoadMore, setRunRequestLoadMore] = useState(false);
  const [loadingMoreUsers, setLoadingMoreUsers] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const firstRender = useRef(true);

  useEffect(() => {
    fetchUsers();
  }, [runRequest]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    fetchLoadMore();
  }, [runRequestLoadMore]);

  const fetchUsers = async () => {
    try {
      if (isLoading == false) {
        setIsLoading(true);
      }

      const queryString = {
        search: searchValue,
        page: currentPage,
        paginate: true,
        load_events: true,
      };

      const params = serializeQueryString(queryString);

      const { data } = await api.get(`/users${params}`);
      setUsers(data.data);
      setTotalPage(data.last_page);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLoader = () => {
    return (
      <View style={{ height: 40, marginVertical: 40 }}>
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );
  };

  const getLoadingMore = () => {
    return loadingMoreUsers ? getLoader() : null;
  };

  const handleScrollLoadMore = () => {
    if (!loadingMoreUsers) {
      if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1);
        setRunRequestLoadMore(!runRequestLoadMore);
      }
    }
  };

  const fetchLoadMore = async () => {
    try {
      const queryString = {
        search: searchValue,
        page: currentPage,
        paginate: true,
        load_events: true,
      };

      setLoadingMoreUsers(true);
      const params = serializeQueryString(queryString);
      const { data } = await api.get(`/users${params}`);
      setUsers([
        ...users,
        ...data.data.map((item) => ({
          ...item,
        })),
      ]);
    } catch (error) {
      alert(handleHttpError(error));
    } finally {
      setLoadingMoreUsers(false);
    }
  };
  const getIconRight = () => {
    if (clearSearch) {
      return (
        <TextInput.Icon
          icon={require("../../assets/close.svg")}
          color={colors.primary}
          onPress={() => {
            setClearSearch(false);
            setSearchValue("");
            setRunRequest(!runRequest);
            Keyboard.dismiss();
          }}
        />
      );
    }

    return (
      <TextInput.Icon
        icon={require("../../assets/search.svg")}
        onPress={() => {
          setClearSearch(true);
          setRunRequest(!runRequest);
        }}
        color={colors.primary}
      />
    );
  };

  if (isLoading) {
    return (
      <MainLayout>
        <View style={{ padding: 20 }}>{getLoader()}</View>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <View style={{ padding: 20, flex: 1 }}>
        <CardOptions
          icon={require("../../assets/person-circle.png")}
          title="Cadastrar novo usuário"
          mode="white"
          onPress={() => {
            navigation.navigate("RegisterUser", {
              onRefresh: fetchUsers,
            });
          }}
        />

        <View style={{ height: 6 }} />

        <TextInput
          placeholder="Buscar usuário..."
          mode="flat"
          underlineColor="transparent"
          dense={true}
          style={GlobalStyle.textField}
          value={searchValue}
          onChangeText={(value) => setSearchValue(value)}
          right={getIconRight()}
          onSubmitEditing={() => {
            setCurrentPage(1);
            setClearSearch(true);
            setRunRequest(!runRequest);
          }}
        />

        <FlatList
          data={users}
          renderItem={({ item }) => (
            <ItemUser user={item} onRefresh={fetchUsers} />
          )}
          keyExtractor={(item, index) => `${item.id}-${index}`}
          onEndReached={handleScrollLoadMore}
          onEndReachedThreshold={0.1}
          ListFooterComponent={getLoadingMore()}
          style={{ height: 200 }}
        />
      </View>
    </MainLayout>
  );
}

export default UsersPage;
