import { useEffect, useState, useRef } from "react";
import { Text, View, FlatList, Keyboard, ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";

import {
  useTheme,
  Button,
  TextInput,
  Dialog,
  Portal,
  ActivityIndicator,
  Snackbar,
} from "react-native-paper";

import CardOptions from "../../components/CardOptions";
import MainLayout from "../../layouts/MainLayout";
import ItemParticipant from "../../components/ItemParticipant";
import { handleHttpError } from "../../utils";
import GlobaStyles from "../../../styles";
import api from "../../services/api";
import SentryHandler from "../../utils/sentry_handler";
import GlobalStyle from "../../../styles";

function CheckinParticipants({ route }) {
  const { colors } = useTheme();
  const { event, onRefresh } = route.params;
  const navigation = useNavigation();

  const [valueSearchParticipant, setValueSearchParticipant] = useState("");
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [errorCheckin, setErrorCheckin] = useState(null);
  const [loadingCheckin, setLoadingCheckin] = useState(false);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [loadingParticipants, setLoadingParticipants] = useState(true);
  const [msgErrorParticipants, setMsgErrorParticipants] = useState(null);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMoreParticipants, setLoadingMoreParticipants] = useState(false);
  const [runRequestLoadMore, setRunRequestLoadMore] = useState(false);
  const [runRequest, setRunRequest] = useState(false);
  const firstRender = useRef(true);

  const [
    visibleSnackBarErrorParticipants,
    setVisibleSnackBarErrorParticipants,
  ] = useState(false);

  useEffect(() => {
    navigation.setOptions({
      title: event.name,
    });
  }, []);

  useEffect(() => {
    fetchParticipants();
  }, [runRequest]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    loadMoreParticipants();
  }, [runRequestLoadMore]);

  const fetchParticipants = async () => {
    try {
      setLoadingParticipants(true);
      setMsgErrorParticipants(null);

      const { data } = await api.get(
        `/events/${event.id}/participants?page=${currentPage}&search=${valueSearchParticipant}`
      );

      setParticipants(data.data);
      setTotalPage(data.last_page);
    } catch (error) {
      new SentryHandler(error);
      setMsgErrorParticipants(handleHttpError(error));
      setVisibleSnackBarErrorParticipants(true);
    } finally {
      setLoadingParticipants(false);
    }
  };

  const loadMoreParticipants = async () => {
    try {
      setLoadingMoreParticipants(true);
      setMsgErrorParticipants(null);

      const { data } = await api.get(
        `/events/${event.id}/participants?page=${currentPage}&search=${valueSearchParticipant}`
      );

      setParticipants([...participants, ...data.data]);
      // setTotalPage(data.last_page);
    } catch (error) {
      new SentryHandler(error);
      setMsgErrorParticipants(handleHttpError(error));
      setVisibleSnackBarErrorParticipants(true);
    } finally {
      setLoadingMoreParticipants(false);
    }
  };

  const hideDialog = () => setVisibleDialog(false);

  const emptyList = () => {
    return (
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={GlobaStyles.titleHeader}>
          Nenhum participante encontrado
        </Text>
      </View>
    );
  };

  const doCheckin = async () => {
    try {
      setLoadingCheckin(true);
      await api.post(`/checkin`, {
        cpf: currentParticipant.cpf,
        event_id: event.id,
      });
      setCurrentPage(1);
      hideDialog();
      onRefresh();
      setRunRequest(!runRequest);
      // fetchParticipants();
    } catch (error) {
      new SentryHandler(error);
      setErrorCheckin(handleHttpError(error));
      // if (error.response && error.response.status == 422) {
      //   setErrorCheckin("Checkin já relizado");
      // } else {
      //   setErrorCheckin(handleHttpError(error));
      // }
    } finally {
      setLoadingCheckin(false);
    }
  };

  const handleClickCheckin = (participant) => {
    setCurrentParticipant(participant);
    setVisibleDialog(true);
  };

  const isEventFree = () => event.price === 0;

  const getLoader = () => {
    return (
      <View style={{ height: 40, marginVertical: 40 }}>
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );
  };

  const getLoadingMore = () => {
    return loadingMoreParticipants ? getLoader() : null;
  };

  const handleScrollLoadMore = () => {
    if (!loadingMoreParticipants) {
      console.log("CHamou 1");

      if (currentPage < totalPage) {
        console.log("CHamou 2");
        setCurrentPage(currentPage + 1);
        setRunRequestLoadMore(!runRequestLoadMore);
      }
    }
  };

  const buildParticipants = () => {
    if (loadingParticipants) {
      return getLoader();
    }

    return (
      <FlatList
        data={participants}
        renderItem={({ item }) => (
          <ItemParticipant item={item} onClickCheckin={handleClickCheckin} />
        )}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        onEndReached={handleScrollLoadMore}
        onEndReachedThreshold={0.1}
        ListEmptyComponent={emptyList()}
        ListFooterComponent={getLoadingMore()}
        style={{ height: 200 }}
      />
    );
  };

  const getIconRight = () => {
    if (valueSearchParticipant) {
      return (
        <TextInput.Icon
          icon="close"
          color={colors.primary}
          onPress={() => {
            setValueSearchParticipant("");
            setRunRequest(!runRequest);
            Keyboard.dismiss();
          }}
        />
      );
    }

    return (
      <TextInput.Icon
        icon="magnify"
        size={30}
        onPress={fetchParticipants}
        color={colors.primary}
      />
    );
  };

  return (
    <MainLayout>
      <View style={{ padding: 16, paddingTop: 30 }}>
        {isEventFree() && (
          <CardOptions
            icon="account-plus-outline"
            title="Inscrição rápida"
            mode="white"
            onPress={() =>
              navigation.navigate("QuickSubscription", {
                event,
                onRefresh: () => onRefresh(),
              })
            }
          />
        )}

        <TextInput
          mode="flat"
          underlineColor="transparent"
          placeholder="Digite o nome do participante"
          dense={true}
          style={GlobalStyle.textField}
          value={valueSearchParticipant}
          onChangeText={(value) => setValueSearchParticipant(value)}
          right={getIconRight()}
          onSubmitEditing={() => {
            setCurrentPage(1);
            setRunRequest(!runRequest);
          }}
        />
      </View>

      <View
        style={{
          flex: 1,
          paddingHorizontal: 14,
          paddingVertical: 8,
        }}
      >
        {buildParticipants()}
      </View>

      <Portal>
        <Dialog
          visible={visibleDialog}
          onDismiss={hideDialog}
          style={{ backgroundColor: "#fff" }}
        >
          <Button
            icon="check-decagram"
            style={{ marginTop: 20 }}
            labelStyle={{ fontSize: 80, color: colors.success }}
          />

          {errorCheckin && (
            <Text
              style={{
                color: colors.error,
                textAlign: "center",
                marginTop: 6,
                marginBottom: 6,
              }}
            >
              {errorCheckin}
            </Text>
          )}

          {!errorCheckin && (
            <View style={{ padding: 20 }}>
              <Button
                mode="contained"
                onPress={doCheckin}
                color={colors.primary}
                contentStyle={{ height: 50 }}
                style={{ marginBottom: 6 }}
                disabled={loadingCheckin}
              >
                {loadingCheckin ? "realizando..." : "realizar checkin"}
              </Button>
              <Button
                mode="outlined"
                onPress={hideDialog}
                contentStyle={{ height: 50 }}
                style={{ borderWidth: 1, borderColor: colors.danger }}
                color={colors.danger}
              >
                cancelar
              </Button>
            </View>
          )}
        </Dialog>
      </Portal>

      <Snackbar
        visible={visibleSnackBarErrorParticipants}
        duration={3000}
        onDismiss={() => setVisibleSnackBarErrorParticipants(false)}
      >
        {msgErrorParticipants}
      </Snackbar>
    </MainLayout>
  );
}

export default CheckinParticipants;
