import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  KeyboardAvoidingView,
  ActivityIndicator,
  Keyboard,
  Platform,
  Dimensions,
} from "react-native";
import { Card, Title, useTheme, Button, RadioButton } from "react-native-paper";
import { useMediaQuery } from "react-responsive";
import GlobalStyle from "../../../styles";
import TextField from "../../components/TextField";
import TextFieldPassword from "../../components/TextFieldPassword";
import Error from "../../components/Error";
import useForm from "../../hooks/useForm";
import Storage from "../../utils/storage";
import api from "../../services/api";
import {
  handleHttpError,
  encodeBase64,
  getEnvironmentText,
  maskCpf,
} from "../../utils";
import PasswordEncrypt from "../../utils/password_encrypt";
import SentryHandler from "../../utils/sentry_handler";

const WIDTH_DISPLAY = Dimensions.get("window").width;
const isAndroid = Platform.OS === "android";
const isWeb = Platform.OS === "web";

function Login({ navigation, handlerContextUser }) {
  const [loadingToken, setLoadingToken] = useState(true);
  const [isMakingLogin, setIsMakingLogin] = useState(false);
  const [erroLogin, setErrorLogin] = useState(null);
  const [textEnv, setTextEnv] = useState("");
  const username = useForm(["required"]);
  const password = useForm(["required"]);
  const type = useForm(["required"], "interno");
  const { colors } = useTheme();

  const isMobileScreen = useMediaQuery({
    maxDeviceWidth: 1224,
  });

  useEffect(() => {
    verifyHasToken();
    setTextEnv(getEnvironmentText(process.env.REACT_APP_LOCAL));
  }, []);

  async function verifyHasToken() {
    const token = await Storage.getToken();
    if (token) navigation.navigate("Root");
    setLoadingToken(false);
  }

  async function handleSubmit() {
    try {
      if (!username.isValid() && !password.isValid()) return;

      setIsMakingLogin(true);
      setErrorLogin(null);

      const passwordEncrypted = new PasswordEncrypt(password.value);

      let login = username.value.trim().toLowerCase();

      if (type.value === "externo") {
        username.setValue(maskCpf(login));
        login = maskCpf(login);
      }

      const payload = {
        login: login,
        password: passwordEncrypted.get(),
        type: type.value,
      };

      Keyboard.dismiss();
      const { data } = await api.post("/auth", payload);
      await Storage.setData(data);
      handlerContextUser();
      navigation.navigate("Root");
    } catch (error) {
      setErrorLogin(handleHttpError(error));
      // setErrorLogin("Usuário ou senha inválido");
      new SentryHandler(error);
    } finally {
      setIsMakingLogin(false);
    }
  }

  if (loadingToken) {
    return (
      <View style={GlobalStyle.containerCenter} testID="activityIndicator">
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      keyboardVerticalOffset={isAndroid ? 40 : 0}
      behavior={!isAndroid ? "padding" : ""}
    >
      <View
        style={{
          backgroundColor: colors.primary,
          flex: 1,
          padding: 10,

          alignItems: "center",
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            width: isWeb ? (isMobileScreen ? 560 : 600) : WIDTH_DISPLAY,
            paddingHorizontal: isWeb ? (isMobileScreen ? 100 : 0) : 10,
            paddingBottom: isAndroid ? 150 : 20,
          }}
        >
          <Image
            style={{
              width: "100%",
              height: 250,
              alignSelf: "center",
            }}
            source={require("../../assets/logo_white.png")}
          />
          <Title style={GlobalStyle.title}>Check-in Sebrae MG {textEnv}</Title>
          <TextField
            testID="userInput"
            placeholder="Usuário"
            autoComplete="off"
            {...username}
          />

          <TextFieldPassword
            testID="passwordInput"
            placeholder="Senha"
            autoComplete="off"
            {...password}
          />

          <Error error={erroLogin} />

          <RadioButton.Group>
            <RadioButton.Item
              label="Sebrae"
              color="#fff"
              uncheckedColor="#fff"
              status={type.value === "interno" ? "checked" : "unchecked"}
              onPress={() => type.setValue("interno")}
              labelStyle={{
                color: "#fff",
                fontWeight: "bold",
              }}
            />
            <RadioButton.Item
              label="Parceiro"
              status={type.value === "externo" ? "checked" : "unchecked"}
              onPress={() => type.setValue("externo")}
              color="#fff"
              uncheckedColor="#fff"
              labelStyle={{
                color: "#fff",
                fontWeight: "bold",
              }}
            />
          </RadioButton.Group>
        </View>
        <Button
          mode="contained"
          style={{
            marginTop: 10,
            marginBottom: 20,
            marginHorizontal: isAndroid ? 20 : 0,
            height: 45,
            justifyContent: "center",
            width: "100%",
            maxWidth: isWeb ? (isMobileScreen ? 560 : 600) : WIDTH_DISPLAY,
          }}
          onPress={handleSubmit}
          loading={isMakingLogin}
          disabled={isMakingLogin}
          testID="buttonLogin"
          color={colors.success}
        >
          <Text style={{ color: colors.accent }}>
            {isMakingLogin ? "Entrando..." : "Entrar"}
          </Text>
        </Button>
      </View>
    </KeyboardAvoidingView>
  );
}

export default Login;

const style = StyleSheet.create({
  card: {
    width: "100%",
    maxWidth: 600,
    padding: 10,
  },
  input: {
    marginTop: 5,
    marginBottom: 5,
  },
  button: {
    marginTop: 14,
    height: 60,
    alignItems: "center",
    justifyContent: "center",
  },
});
