import React from "react";
import { DialogConfirmProvider } from "./useDialogConfirm";
import { DialogLoaderProvider } from "./useDialogLoader";
import { DialogResultMessageProvider } from "./useDialogResultMessage";

const AppProvider = ({ children }) => (
  <>
    <DialogLoaderProvider>
      <DialogResultMessageProvider>
        <DialogConfirmProvider>{children}</DialogConfirmProvider>
      </DialogResultMessageProvider>
    </DialogLoaderProvider>
  </>
);

export default AppProvider;
