import React, { useState } from "react";
import { validateCpf } from "../utils";

const VALIDATIONS = {
  required: {
    validate: (value) => {
      const msg = "Campo obrigatório";
      if (Array.isArray(value) && value.length === 0) return msg;
      if (value === "" || value === null) return msg;
      return true;
    },
  },
  cep: {
    validate: (value) => {
      if (!value) return true;
      if (!/^\d{5}-?\d{3}$/.test(value)) return "CEP inválido";
      return true;
    },
  },
  email: {
    validate: (value) => {
      if (!value) return true;
      if (!/\S+@\S+\.\S+/.test(value)) return "Email inválido";
      return true;
    },
  },
  phone: {
    validate: (value) => {
      if (!value) return true;
      if (!/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/.test(value))
        return "Telefone inválido";
      return true;
    },
  },
  cpf: {
    validate: (cpf) => (validateCpf(cpf) ? true : "CPF inválido"),
  },
};

const useForm = (type = [], initialValue = "") => {
  const [error, setError] = useState(null);
  const [value, setValue] = useState(initialValue);

  if (!type || !Array.isArray(type))
    throw "Hook useForm: Informe um Array para validações";

  function validate(params) {
    try {
      type.reduce((acc, field) => {
        if (!VALIDATIONS[field]) throw "Hook useForm: Validação não existe";

        const isValid = VALIDATIONS[field].validate(params);

        if (isValid === true) {
          return true;
        } else {
          throw `${isValid}`;
        }
      }, true);
    } catch (error) {
      setError(error);
      return false;
    }

    setError(null);
    return true;
  }

  function onChange(text) {
    if (error) validate(text);
    setValue(text);
  }

  return {
    value,
    setValue,
    error,
    setError,
    onChange,
    isValid: () => validate(value),
    onBlur: () => validate(value),
  };
};

export default useForm;
