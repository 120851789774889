import { Text, View, Modal, StyleSheet } from "react-native";
import { Avatar, Button, useTheme } from "react-native-paper";

function ItemParticipant({ item, onClickCheckin }) {
  const { roundness, colors } = useTheme();

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 20,
        paddingHorizontal: 10,
        backgroundColor: "#F5F6FA",
        borderRadius: roundness,
        marginBottom: 10,
      }}
    >
      <View>
        <Avatar.Icon size={50} icon={require("../assets/user.svg")} />
      </View>
      <View style={{ flex: 1 }}>
        <View style={{ marginLeft: 20, marginBottom: 6 }}>
          <Text
            style={{ fontWeight: "bold", color: colors.primary, fontSize: 14 }}
          >
            {item.name ? item.name.toUpperCase() : "Nome não disponível"}
          </Text>
          <Text style={{ fontSize: 12, marginTop: 8 }}>{item.cpf}</Text>
        </View>
      </View>

      <View>
        {item.checkin.checkin_id === null ? (
          <Button
            style={{
              backgroundColor: colors.primary,
              padding: 0,
            }}
            labelStyle={{ fontSize: 10, padding: 0 }}
            onPress={() => onClickCheckin(item)}
            icon="check"
            color={colors.accent}
          >
            CHECKIN
          </Button>
        ) : (
          <Button icon="check" />
        )}
      </View>
    </View>
  );
}

export default ItemParticipant;
