import { AES } from "crypto-js";
import EncHex from "crypto-js/enc-hex";
import PadZeroPadding from "crypto-js/pad-zeropadding";

export default class PasswordEncrypt {
  constructor(pass) {
    this.password = pass;
    this.key = "0123456789abcdef0123456789abcdef";
    this.iv = "abcdef9876543210abcdef9876543210";
  }

  get() {
    const keyEncHex = EncHex.parse(this.key);
    const ivEncHex = EncHex.parse(this.iv);

    const cryptedPassword = AES.encrypt(this.password, keyEncHex, {
      iv: ivEncHex,
      padding: PadZeroPadding,
    }).toString();

    return cryptedPassword;
  }
}
