import { Platform } from "react-native";

export const handleHttpError = (error) => {
  console.log("ERROR: ", error);
  let msg = "";
  if (!error.response) {
    msg = "Houve um erro inesperado. Tente novamente mais tarde.";
  } else {
    const status = error.response.status;
    switch (status) {
      case 400:
        msg = error.response.data.message;
        break;
      case 404:
        msg = error.response.data.message
          ? error.response.data.message
          : "Houve um erro inesperado. Recurso não encontrado";
        break;
      case 422:
        if (!error.response.data) {
          msg = "Houve um erro inesperado";
          return msg;
        }

        if (
          error.response.data.errors &&
          typeof error.response.data.errors === "object"
        ) {
          Object.entries(error.response.data.errors).forEach(([key, value]) => {
            msg = value[0];
          });

          return msg;
        }

        msg = error.response.data.message;
        break;
      case 401:
        msg = error.response.data.message
          ? error.response.data.message
          : "Você não tem permissão para acessar esse recurso. Efetue o login novamete";
        break;
      case 500:
        msg = error.response.data.message
          ? error.response.data.message
          : "Houve um problema ao comunicar-se com o servidor. Tente novamente mais tarde.";
        break;
      default:
        msg = "Houve um erro inesperado. Tente novamente mais tarde.";
    }
  }

  return msg;
};

export const isCpfValid = (value) => {
  let cpf = value.trim();

  cpf = cpf.replace(/\./g, "");
  cpf = cpf.replace("-", "");
  cpf = cpf.split("");

  let v1 = 0;
  let v2 = 0;
  let aux = false;

  for (let i = 1; cpf.length > i; i++) {
    if (cpf[i - 1] != cpf[i]) {
      aux = true;
    }
  }

  if (aux == false) {
    return false;
  }

  for (let i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
    v1 += cpf[i] * p;
  }

  v1 = (v1 * 10) % 11;

  if (v1 == 10) {
    v1 = 0;
  }

  if (v1 != cpf[9]) {
    return false;
  }

  for (let i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
    v2 += cpf[i] * p;
  }

  v2 = (v2 * 10) % 11;

  if (v2 == 10) {
    v2 = 0;
  }

  if (v2 != cpf[10]) {
    return false;
  } else {
    return true;
  }
};

export const getCurrentDate = () => {
  const today = new Date();
  const month = today.getMonth() + 1;
  const monthText = month.toString().padStart(2, "0");
  const todayText = today.getDate().toString().padStart(2, "0");
  // return `2022-03-07`;

  return `${today.getFullYear()}-${monthText}-${todayText}`;
};

export const getFutureDate = () => {
  var myFutureDate = new Date();
  myFutureDate.setDate(myFutureDate.getDate() + 30);

  const month = myFutureDate.getMonth() + 1;
  const monthText = month.toString().padStart(2, "0");
  const todayText = myFutureDate.getDate().toString().padStart(2, "0");

  return `${myFutureDate.getFullYear()}-${monthText}-${todayText}`;
};

export const serializeQueryString = (query) => {
  let queryString = "";
  for (let key in query) {
    const value = query[key] === null ? "" : query[key];
    if (queryString === "") {
      queryString = `?${key}=${value}`;
    } else {
      queryString += `&${key}=${value}`;
    }
  }
  return queryString;
};

export const replaceWordsAccent = (word) => {
  let r = word ? word.toString().toLowerCase() : "";
  r = r.replace(new RegExp("\\s", "g"), "");
  r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
  r = r.replace(new RegExp("æ", "g"), "ae");
  r = r.replace(new RegExp("ç", "g"), "c");
  r = r.replace(new RegExp("[èéêë]", "g"), "e");
  r = r.replace(new RegExp("[ìíîï]", "g"), "i");
  r = r.replace(new RegExp("ñ", "g"), "n");
  r = r.replace(new RegExp("[òóôõö]", "g"), "o");
  r = r.replace(new RegExp("œ", "g"), "oe");
  r = r.replace(new RegExp("[ùúûü]", "g"), "u");
  r = r.replace(new RegExp("[ýÿ]", "g"), "y");
  r = r.replace(new RegExp("\\W", "g"), "");
  return r;
};

export const validateCpf = (cpf) => {
  if (typeof cpf !== "string") return false;
  let newCpf = cpf.replace(/[^\d]+/g, "");

  if (newCpf.length !== 11 || !!newCpf.match(/(\d)\1{10}/)) return false;

  newCpf = newCpf.split("").map((el) => +el);

  const rest = (count) =>
    ((newCpf
      .slice(0, count - 12)
      .reduce((soma, el, index) => soma + el * (count - index), 0) *
      10) %
      11) %
    10;

  const isValid = rest(10) === newCpf[9] && rest(11) === newCpf[10];

  return isValid;
};

export const validateCnpj = (value) => {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === "string";
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  // Elimina valor em formato inválido
  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Limita ao máximo de 18 caracteres, para CNPJ formatado
    if (value.length > 18) return false;

    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

    // Se o formato é válido, usa um truque para seguir o fluxo da validação
    if (digitsOnly || validFormat) true;
    // Se não, retorna inválido
    else return false;
  }

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
};

export const getNameMonth = (monthNumber) => {
  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const index = parseInt(monthNumber) === 0 ? 0 : parseInt(monthNumber) - 1;
  return monthNames[index];
};

export const canMakeCheckin = (dateStart, dateEnd) => {
  const isWeb = Platform.OS === "web";

  // Formato: 13/07/2020 18:00
  const [dateFullStart, hourStart] = dateStart.split(" ");
  const [dateFullEnd, hourEnd] = dateEnd.split(" ");

  let dateStartParsed = null;
  let dateEndParsed = null;

  if (isWeb) {
    dateStartParsed = new Date(
      `${dateFullStart.split("/").reverse().join("/")} ${hourStart}`
    );

    dateEndParsed = new Date(
      `${dateFullEnd.split("/").reverse().join("/")} ${hourEnd}`
    );
  } else {
    const [startDay, startMonth, startYear] = dateFullStart.split("/");
    const [startHour, startMinute] = hourStart.split(":");

    const [endDay, endMonth, endYear] = dateFullEnd.split("/");
    const [endHour, endMinute] = hourEnd.split(":");

    dateStartParsed = new Date(
      Date.UTC(startYear, startMonth - 1, startDay, startHour, startMinute)
    );
    dateEndParsed = new Date(
      Date.UTC(endYear, endMonth - 1, endDay, endHour, endMinute)
    );
  }

  const today = new Date();

  const now = isWeb
    ? new Date()
    : new Date(
        Date.UTC(
          today.getUTCFullYear(),
          today.getUTCMonth(),
          today.getUTCDate(),
          today.getHours(),
          today.getMinutes()
        )
      );

  // console.log({
  //   os: Platform.OS,
  //   now,
  //   start: dateFullStart,
  //   end: dateFullEnd,
  //   startParsed: dateStartParsed,
  //   endParsed: dateEndParsed,
  //   timeStart: dateStartParsed.getTime(),
  //   timeEnd: dateEndParsed.getTime(),
  // });

  return (
    now.getTime() >= dateStartParsed.getTime() &&
    now.getTime() <= dateEndParsed.getTime()
  );
};

export const phoneMasked = (phone) => {
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
  return phone;
};

export const cnpjMasked = (cnpj) => {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
};

export const maskCpf = (cpf) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
};

export const getEnvironmentText = (local) => {
  const environments = {
    development: "(Dev)",
    homologation: "(Hm)",
    local: "(Local)",
  };

  return environments[local] ? environments[local] : "";
};
