import { View, Text, Platform } from "react-native";
import { useTheme, Button } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";

function ItemUser({ user, onRefresh }) {
  const { colors } = useTheme();
  const navigation = useNavigation();

  const isWeb = Platform.OS === "web";

  return (
    <View
      style={{
        flexDirection: isWeb ? "row" : "column",
        justifyContent: "space-between",
        alignItems: isWeb ? "center" : "stretch",
        backgroundColor: "#F5F6FA",
        paddingHorizontal: 14,
        paddingVertical: 8,
        borderTopEndRadius: 10,
        borderTopStartRadius: 10,
        borderBottomEndRadius: 10,
        borderBottomStartRadius: 10,
        marginBottom: 4,
      }}
    >
      <View>
        <Text
          style={{
            color: colors.primary,
            fontWeight: "bold",
            fontSize: 20,
          }}
        >
          {user.nome}
        </Text>
        <View style={{ flexDirection: "row", marginTop: 4 }}>
          <Text
            style={{
              color: colors.primary,
            }}
          >
            CPF:
          </Text>
          <Text
            style={{
              color: "#000",
              marginLeft: 4,
            }}
          >
            {user.cpf}
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 4 }}>
          <Text
            style={{
              color: colors.primary,
            }}
          >
            Empresa:
          </Text>
          <Text
            style={{
              color: "#000",
              marginLeft: 4,
            }}
          >
            {user.empresa}
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 4 }}>
          <Text
            style={{
              color: colors.primary,
            }}
          >
            Telefone:
          </Text>
          <Text
            style={{
              color: "#000",
              marginLeft: 4,
            }}
          >
            {user.telefone}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: isWeb ? 0 : 10 }}>
        <Button
          icon="pencil"
          mode="outlined"
          color={colors.primary}
          onPress={() => {
            navigation.navigate("EditRegisterUser", {
              user,
              onRefresh,
            });
          }}
        >
          Editar
        </Button>
      </View>
    </View>
  );
}

export default ItemUser;
