import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  FlatList,
  ActivityIndicator,
  Text,
  Keyboard,
  Platform,
  Linking,
} from "react-native";
import {
  useTheme,
  TextInput,
  IconButton,
  Subheading,
} from "react-native-paper";
import * as Location from "expo-location";
import api from "../../services/api";
import { handleHttpError } from "../../utils";
import Error from "../../components/Error";
import Button from "../../components/Button";
import ItemEvent from "../../components/ItemEvent";
import MainLayout from "../../layouts/MainLayout";
import Constants from "expo-constants";
import { useDialogConfirm } from "../../hooks/useDialogConfirm";

import {
  getCurrentDate,
  serializeQueryString,
  getFutureDate,
  getNameMonth,
} from "../../utils";
import Storage from "../../utils/storage";

import GlobalStyle from "../../../styles";
import SentryHandler from "../../utils/sentry_handler";

const CURRENT_DATE = getCurrentDate();
const isAndroid = Platform.OS === "android";
const isIOS = Platform.OS === "ios";
const isWEB = Platform.OS === "web";

const QUERY_STRING = {
  limit: 10,
  start: CURRENT_DATE,
  status: "Active",
  longitude: null,
  latitude: null,
  // longitude: -43.97169462524278,
  // latitude: -19.93720561129948,
  order_by: "date_desc",
};

function Events({ navigation }) {
  const { colors } = useTheme();
  const dialogConfirm = useDialogConfirm();
  const [fetchingEvents, setFetchingEvents] = useState(true);
  const [errorFetchingEvents, setErrorFetchingEvents] = useState(null);
  const [loadingMoreEvents, setLoadingMoreEvents] = useState(false);
  const [events, setEvents] = useState([]);
  const [errorPermission, setErrorPermission] = useState(null);
  const [location, setLocation] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [runRequest, setRunRequest] = useState(false);
  const [runRequestLoadMore, setRunRequestLoadMore] = useState(false);
  const firstRender = useRef(true);

  useEffect(() => {
    handleRequestPermissionLocation();
    fetchCurrentVersionApp();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [runRequest]);

  useEffect(() => {
    setHeader();
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    fetchLoadMore();
  }, [runRequestLoadMore]);

  const fetchCurrentVersionApp = async () => {
    try {
      if (isWEB) return;

      const version = Constants.manifest.version;

      const { data } = await api.get("/app-version");
      if (version !== data.version) {
        await dialogConfirm.show({
          title: "Atualização",
          message: "Seu aplicativo precisa de atualização",
          showCancelButton: false,
        });

        Linking.openURL(data[Platform.OS]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setHeader = async () => {
    const data = await Storage.getData();
    // console.log(data);
    navigation.setOptions({
      headerRight: (props) => {
        return (
          <Text
            style={{
              fontSize: 16,
              marginRight: 12,
              color: "#FFF",
              fontWeight: "bold",
            }}
          >
            {data.login}
          </Text>
        );
      },
    });
  };

  const handleRequestPermissionLocation = async () => {
    const permission = await Location.requestForegroundPermissionsAsync();
    if (isAndroid) {
      if (permission.status !== "granted") {
        setErrorPermission(
          "Necessário sua permissão para obter sua localização, para encontrarmos eventos próximos"
        );
        await Location.requestForegroundPermissionsAsync();
      } else {
        if (permission.status === "granted") {
          const location = await Location.getCurrentPositionAsync({
            accuracy: 6,
          });

          setLocation(location);
          setRunRequest(!runRequest);
        }
      }
    } else if (isIOS) {
      if (permission.status !== "granted") {
        setErrorPermission(
          "Necessário sua permissão para obter sua localização, para encontrarmos eventos próximos"
        );
        await Location.requestForegroundPermissionsAsync();
        return;
      } else {
        if (permission.status === "granted") {
          const location = await Location.getCurrentPositionAsync({
            accuracy: 6,
          });

          setLocation(location);
          setRunRequest(!runRequest);
        }
      }
    } else {
      if (permission.granted === false) {
        setErrorPermission(
          "Necessário sua permissão para obter sua localização, para encontrarmos eventos próximos"
        );
        return;
      }

      const location = await Location.getCurrentPositionAsync({
        accuracy: 6,
      });

      setLocation(location);
      setRunRequest(!runRequest);
    }
  };

  const handleScrollLoadMore = () => {
    if (!loadingMoreEvents) {
      if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1);
        setRunRequestLoadMore(!runRequestLoadMore);
      }
    }
  };

  const getDateFormatBr = () => {
    const [year, month, day] = CURRENT_DATE.split("-");
    return `${day} de ${getNameMonth(month)} de ${year}`;
  };

  const fetchLoadMore = async () => {
    const { latitude, longitude } = location.coords;
    const queryObj = {
      ...QUERY_STRING,
      like: searchValue,
      page: currentPage,
      longitude,
      latitude,
    };

    try {
      setLoadingMoreEvents(true);
      const queryString = serializeQueryString(queryObj);
      const { data } = await api.get(`/events${queryString}`);
      setEvents([
        ...events,
        ...data.data.map((item) => ({
          ...item,
        })),
      ]);
      setTotalPage(data.last_page);
    } catch (error) {
      console.log(error);
      alert(handleHttpError(error));
      new SentryHandler(error);
    } finally {
      setLoadingMoreEvents(false);
    }
  };

  const fetchEvents = async () => {
    if (!location) return;
    const { latitude, longitude } = location.coords;

    console.log("latitude", latitude);
    console.log("longitude", longitude);
    const queryObj = {
      ...QUERY_STRING,
      like: searchValue,
      page: currentPage,
      longitude,
      latitude,
    };
    const queryString = serializeQueryString(queryObj);

    try {
      setFetchingEvents(true);
      setErrorFetchingEvents(null);
      const { data } = await api.get(`/events${queryString}`);
      setEvents(
        data.data.map((item) => ({
          ...item,
        }))
      );
      setTotalPage(data.last_page);
    } catch (error) {
      new SentryHandler(error);
      const { response } = error;
      if (response && response.status && response.status === 401) {
        Storage.deleteData();
        navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
        return;
      }

      setErrorFetchingEvents(handleHttpError(error));
    } finally {
      setFetchingEvents(false);
    }
  };

  const getLoader = () => {
    return (
      <View style={{ height: 40, marginVertical: 40 }}>
        <ActivityIndicator color={colors.primary} size="large" />
      </View>
    );
  };

  const getIconRight = () => {
    if (searchValue) {
      return (
        <TextInput.Icon
          icon="close"
          color={colors.primary}
          onPress={() => {
            setSearchValue("");
            setRunRequest(!runRequest);
            Keyboard.dismiss();
          }}
        />
      );
    }

    return (
      <TextInput.Icon
        icon="magnify"
        size={30}
        onPress={fetchEvents}
        color={colors.primary}
      />
    );
  };

  const buildBody = () => {
    if (fetchingEvents) return getLoader();

    if (!fetchingEvents && events.length === 0)
      return (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Subheading>
            Desculpa, nenhum evento disponível para a data {getDateFormatBr()}
          </Subheading>
        </View>
      );

    if (searchValue && events.length === 0)
      return (
        <View style={GlobalStyle.containerCenter}>
          <Error error="Nenhum evento encontrado" />
        </View>
      );

    return (
      <FlatList
        data={events}
        renderItem={({ item }) => (
          <ItemEvent
            item={item}
            onPress={() => {
              navigation.navigate("EventDetails", { event: item });
            }}
          />
        )}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        onEndReached={handleScrollLoadMore}
        onEndReachedThreshold={0.1}
        ListFooterComponent={getLoadingMore()}
        style={{ height: 200 }}
      />
    );
  };

  const getLoadingMore = () => {
    return loadingMoreEvents ? getLoader() : null;
  };

  if (errorPermission) {
    return (
      <View style={GlobalStyle.containerCenter}>
        <Error error={errorPermission} />
        <Text>
          Acesse as configuração do seu {isWEB ? "navegador" : "dispositivo"},
          permita a localização e
          {isWEB
            ? " efetue um refresh na página"
            : "feche a abra o aplicativo novamente"}
        </Text>
      </View>
    );
  }
  if (errorFetchingEvents) {
    return (
      <View style={GlobalStyle.containerCenter}>
        <Error error={errorFetchingEvents} />
        <Button text="Recarregar" onPress={fetchEvents} />
      </View>
    );
  }
  if (!location) {
    return <View style={GlobalStyle.containerCenter}>{getLoader()}</View>;
  }

  return (
    <MainLayout>
      <View style={styles.padding}>
        <TextInput
          placeholder="Buscar evento..."
          mode="flat"
          underlineColor="transparent"
          dense={true}
          style={GlobalStyle.textField}
          value={searchValue}
          onChangeText={(value) => setSearchValue(value)}
          right={getIconRight()}
          onSubmitEditing={() => {
            setCurrentPage(1);
            setRunRequest(!runRequest);
          }}
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: colors.primary,
            borderRadius: 10,
            marginTop: 4,
          }}
        >
          <IconButton icon="calendar" color={colors.accent} />
          <Text style={{ color: colors.accent, fontWeight: "bold" }}>
            {getDateFormatBr()}
          </Text>
        </View>
      </View>

      <View
        style={{
          backgroundColor: "#F5F6FA",
          borderRadius: 10,
          flex: 1,
          padding: 6,
          paddingTop: 10,
        }}
      >
        {buildBody()}
      </View>

      {/* <ModalLogout
        visible={modalVisible}
        onPressClose={() => setModalVisible(false)}
      /> */}
    </MainLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  formSearch: {
    height: "auto",
    width: "100%",
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 32,
  },
  padding: {
    paddingTop: 16,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
});

export default Events;
