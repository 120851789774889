import { useState, useEffect } from "react";
import { Text, View, Keyboard } from "react-native";
import { TextInput, useTheme, Button } from "react-native-paper";
import { Masks, useMaskedInputProps } from "react-native-mask-input";
import { useNavigation } from "@react-navigation/native";
import DialogAlert from "../../components/DialogAlert";
import { useDialogConfirm } from "../../hooks/useDialogConfirm";
import { useDialogLoader } from "../../hooks/useDialogLoader";
import { validateCpf, handleHttpError } from "../../utils";
import SentryHandler from "../../utils/sentry_handler";
import GlobalStyles from "../../../styles";
import MainLayout from "../../layouts/MainLayout";
import api from "../../services/api";

function CheckinCpf({ route }) {
  const { colors } = useTheme();
  const { event, onRefresh } = route.params;
  const navigation = useNavigation();
  const confirmDialog = useDialogConfirm();
  const loader = useDialogLoader();

  const [cpf, setCpf] = useState("");
  const [errorCpf, setErrorCpf] = useState(null);
  const [errorCheckin, setErrorCheckin] = useState(null);
  const [doingCheckin, setDoingCheckin] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [participants, setParticipants] = useState([]);
  const maskCpf = useMaskedInputProps({
    value: cpf,
    onChangeText: setCpf,
    mask: Masks.BRL_CPF,
  });

  useEffect(() => {
    navigation.setOptions({
      title: event.name,
    });
  }, []);

  const runValidationCpf = () => {
    if (!validateCpf(cpf)) {
      setErrorCpf("CPF inválido");
      return false;
    } else {
      setErrorCpf(null);
      return true;
    }
  };

  const onClickCheckin = async () => {
    if (!runValidationCpf()) return;

    const cpfNumbers = cpf.replace(/\D/g, "");

    try {
      loader.show("Buscando CPF...");
      const { data } = await api.get(
        `/events/${event.id}/participants?search=${cpfNumbers}`
      );

      if (data.data.length === 0) {
        setErrorCheckin("CPF não encontrado");
        return;
      }

      loader.hide();

      const participant = data.data[0];

      const confirm = await confirmDialog.show({
        title: "Confirmação",
        message: `Deseja confirmar o check-in do(a) participante ${
          participant.name || "Nome não disponível"
        }?`,
      });

      if (confirm === false) return;

      const payload = {
        cpf: cpf,
        event_id: event.id,
      };

      loader.show("Efetuando checkin...");
      Keyboard.dismiss();
      setDoingCheckin(true);
      setErrorCheckin(null);

      confirmCheckin(payload);
    } catch (error) {
      setErrorCheckin(handleHttpError(error));
      new SentryHandler(error);
      setShowDialog(true);
    } finally {
      loader.hide();
    }
  };

  const confirmCheckin = async (payload) => {
    try {
      loader.show("Efetuando checkin...");
      Keyboard.dismiss();
      setDoingCheckin(true);
      setErrorCheckin(null);
      await api.post(`/checkin`, payload);
      setCpf("");
      onRefresh();
    } catch (error) {
      new SentryHandler(error);
      if (error.response && error.response.status == 422) {
        setErrorCheckin("Checkin já relizado");
      } else {
        setErrorCheckin(handleHttpError(error));
      }
    } finally {
      loader.hide();
      setDoingCheckin(false);
      setShowDialog(true);
    }
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  return (
    <MainLayout>
      <View
        style={{
          flex: 1,
          padding: 6,
          height: "100%",
        }}
      >
        <View>
          <TextInput
            style={{ marginBottom: 4 }}
            error={errorCpf !== null}
            mode="outlined"
            placeholder="Digite o CPF"
            onBlur={runValidationCpf}
            keyboardType="numeric"
            {...maskCpf}
            right={
              <TextInput.Icon
                name="check"
                color={colors.primary}
                onPress={() => {
                  onClickCheckin();
                  Keyboard.dismiss();
                }}
              />
            }
          />
          {errorCpf && (
            <Text style={GlobalStyles.errorValidation}>{errorCpf}</Text>
          )}
        </View>

        <DialogAlert
          show={showDialog}
          hide={hideDialog}
          error={errorCheckin !== null}
          errorMessage={errorCheckin}
          successMessage="Checkin realizado com sucesso!"
        />
      </View>
    </MainLayout>
  );
}

export default CheckinCpf;
