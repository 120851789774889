import React, { useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { Avatar, IconButton, useTheme, Checkbox } from "react-native-paper";

function ItemEventSelectable({ item, onPress }) {
  const [isSelected, setSelection] = useState(item.checked);
  const { colors } = useTheme();

  return (
    <TouchableOpacity
      onPress={() => {
        const checked = !isSelected;
        setSelection(checked);
        item.checked = checked;
        if (onPress) onPress(item);
      }}
      style={{
        backgroundColor: "#F5F6FA",
        marginBottom: 10,
        borderRadius: 10,
      }}
    >
      <View style={styles.card}>
        <View style={{ width: 50 }}>
          <Checkbox
            status={isSelected ? "checked" : "unchecked"}
            color={colors.primary}
          />
        </View>

        <View style={{ flex: 1 }}>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
          >
            <View
              style={{
                borderWidth: 1,
                borderColor: colors.primary,
                alignSelf: "flex-start",
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 10,
              }}
            >
              <Text style={{ color: colors.primary }}>{item.code}</Text>
            </View>
            <View
              style={{
                borderWidth: 1,
                backgroundColor: colors.primary,
                alignSelf: "flex-start",
                marginLeft: 10,
                paddingLeft: 16,
                paddingRight: 16,
                borderRadius: 10,
              }}
            >
              <Text style={{ color: "#fff" }}>
                {item.price !== 0 ? "PAGO" : "GRATUITO"}
              </Text>
            </View>
          </View>
          <Text
            style={{
              flexShrink: 1,
              marginRight: 10,
              marginBottom: 5,
              marginTop: 10,
              fontWeight: "bold",
              color: colors.primary,
            }}
          >
            {item.name}
          </Text>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginLeft: -15,
            }}
          >
            <IconButton icon="calendar" color={colors.primary} />
            <Text style={{ color: colors.primary, fontWeight: "bold" }}>
              {item.start}
              {" até "}
              {item.finish}
            </Text>
          </View>
          <Text style={{ marginTop: 10 }}>{item.city.name}</Text>
        </View>
        <View style={{ alignSelf: "flex-start" }}>
          <Avatar.Image
            size={45}
            source={{
              uri: item.cover.square,
            }}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  card: {
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  informations: {
    marginLeft: 6,
    flex: 1,
    justifyContent: "space-between",
  },
});

export default ItemEventSelectable;
